import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { version } from '../package.json'

// Print out current version
console.info(`AlgoFace - Core Build - Version: ${version}`);

ReactDOM.render(<App />, document.getElementById('root'));
