import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Accordion, Segment, Button, Icon } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import ProductStore from '../stores/ProductStore';
import Slider from 'rc-slider';
import { set } from 'mobx';


const Indicator = styled(Segment)`
  color: var(--live-view-slider-indicator-clr) !important;
  font-family: robo-bold !important;
  font-size: 1rem !important;
  margin: auto 10px !important;
  background: unset !important;
  border: unset !important;
  box-shadow: unset !important;
  padding: 0px !important;
`

const NoPaddingSegmentSidePadded = styled(Segment)`
  background: var(--live-view-slider-bg) !important;
  box-shadow: unset !important;
  width: 250px !important;
  position: absolute !important;
  bottom: 70px !important;
  display: flex !important;
  border: unset !important;
  margin: auto !important;
  padding: 4px 5px !important;
  left: calc(50% - 125px) !important;
` 
const initialHeavinessValue = 75

const initialState = {}

export class MakeupAlphaControl extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.afterChangeHandle = this.afterChangeHandle.bind(this); 
    }
    afterChangeHandle(val){
      window.vmt_module._setHeaviness(val);
      if (!window.SERVICES.vmtcore.getIsLive()) window.SERVICES.vmtcore.render();
    }

    componentWillUnmount(){
      window.vmt_module._setHeaviness(initialHeavinessValue);
      if (!window.SERVICES.vmtcore.getIsLive()) window.SERVICES.vmtcore.render();
    }

    componentWillMount(){
      setTimeout(()=>{
        window.vmt_module._setHeaviness(initialHeavinessValue);
      },2000)
    }

    render(){
        return(
          <NoPaddingSegmentSidePadded title="Makeup Intensity">
            <Indicator>L</Indicator>
            <Slider 
              id="heaviness-slider"
              defaultValue={initialHeavinessValue} 
              min={0} 
              max={100} 
              step={10} 
              onChange={this.afterChangeHandle}/>
            <Indicator>H</Indicator>
          </NoPaddingSegmentSidePadded>
        );
    }
}
MakeupAlphaControl = observer(MakeupAlphaControl);
export default MakeupAlphaControl;
