import * as React from 'react';
import styled from '@emotion/styled';
import {Menu, Icon, Button, Dropdown} from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import $ from 'jquery';
import {observer} from 'mobx-react';


const Link = styled(Menu.Item)`
  color: white !important;
  font-family: robo-reg !important;
  font-size: 1.6rem !important;
  cursor: pointer !important;
  color: var(--nav-txt-clr-A) !important;
  &:hover{
    color: var(--nav-txt-clr-B) !important;
  }
  &:before{
    background:unset !important;
  }
`
const SubMenu = styled(Menu.Menu)`
  color: white !important;
  font-family: robo-reg !important;
  font-size: 1.6rem !important;
  cursor: pointer !important;
`

const CustomDropDown = styled(Dropdown)`
  color: white !important;
  cursor: pointer !important;
  &:hover{
    color: var(--nav-txt-clr-B) !important;
  }
`

const Logo = styled('img')`
  height: 35px;
  width: auto !important;
`

const CustomMenu = styled(Menu)`
  background: var(--nav-bg) !important;
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  border-radius: 0px !important;
`


export class NavigationBar extends React.Component {

  constructor(props){
    super(props);
    this.showBasic = this.showBasic.bind(this);
    this.showNudest = this.showNudest.bind(this);
    this.showArtist = this.showArtist.bind(this);    
    this.showHome = this.showHome.bind(this);
  }
  showHome(){
    if(AppStore.currentPage == AppStore.PagesEnum.MAIN){
      window.open(AppStore.clientCfg.clientHomeUrl, '_blank');
      return;
    }

    if(AppStore.currentApp == AppStore.AppsEnum.BASIC){
      this.showBasic();
    }

    if(AppStore.currentApp == AppStore.AppsEnum.NUDEST){
      this.showNudest();
    }

    if(AppStore.currentApp == AppStore.AppsEnum.ARTIST){
      this.showArtist();
    }
  }
  
  showBasic(){
    if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
      window.SERVICES.video.pause();
    }
    AppStore.resetAppState();
    AppStore.setCurrentApp(AppStore.AppsEnum.BASIC)
  }

  showNudest(){
    AppStore.resetAppState();
    AppStore.setCurrentApp(AppStore.AppsEnum.NUDEST)
  }

  showArtist(){
    AppStore.resetAppState();
    AppStore.setCurrentApp(AppStore.AppsEnum.ARTIST)
  }
  render(){
      return(
        <CustomMenu icon compact fluid>
            <Link className="application-logo-container" onClick={(e)=>window.open(AppStore.clientCfg.clientHomeUrl, '_blank')}>
                <Logo className="application-logo" src={AppStore.clientCfg.clientLogo} />
            </Link>
            <Link onClick={this.showHome}>Home</Link>
            {
              !(AppStore.currentApp == AppStore.AppsEnum.BASIC) && AppStore.multiApp &&
              <Link className="desktop-menu" onClick={this.showBasic}>Makeup AR-tist</Link>
            }
            {
              !(AppStore.currentApp == AppStore.AppsEnum.NUDEST) && AppStore.multiApp && false &&
              <Link className="desktop-menu" onClick={this.showNudest}>Nudemeter</Link>
            }
            {
              !(AppStore.currentApp == AppStore.AppsEnum.ARTIST) && AppStore.multiApp &&
              <Link className="desktop-menu" onClick={this.showArtist}>Product Catalogue</Link>
            }
            {
              AppStore.multiApp &&
              <SubMenu className="mobile-menu" position='right'>
                <CustomDropDown item icon='sidebar'>
                  <Dropdown.Menu>
                    <Dropdown.Item  onClick={this.showBasic} disabled={AppStore.currentApp == AppStore.AppsEnum.BASIC}>Makeup AR-tist</Dropdown.Item>
                    {false && <Dropdown.Item  onClick={this.showNudest} disabled={AppStore.currentApp == AppStore.AppsEnum.NUDEST}>Nudemeter</Dropdown.Item>}
                    <Dropdown.Item  onClick={this.showArtist} disabled={AppStore.currentApp == AppStore.AppsEnum.ARTIST}>Product Catalogue</Dropdown.Item>
                  </Dropdown.Menu>
                </CustomDropDown>
              </SubMenu>
            }
        </CustomMenu>
      );
  }
}

NavigationBar = observer(NavigationBar);
export default NavigationBar;