import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Card, Rating, Statistic, Image, Icon, Item } from 'semantic-ui-react'
import MakeupStore from '../stores/MakeupStore';

 
const CustomCardHeader = styled(Card.Header)`
  cursor: pointer;
  font-family: robo-reg !important;
`
const CustomCardMeta = styled(Card.Meta)`
  cursor: pointer;
  font-family: robo-bold !important;
`
const CustomCardDesc = styled(Card.Description)`
  cursor: pointer;
  font-family: robo-reg !important;
`
const CustomCardImg = styled(Image)`
  cursor: pointer;
  max-width: 130px !important;
  margin: auto;
`
const CustomCard = styled(Card)`
  position : relative;
`
const CustomItemContent = styled(Item.Content)`
  text-align: left;
  font-family: Montserrat !important;
`

const CustomIcon = styled(Icon)`
  cursor: pointer;
  position : absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  color: white:
`


const initialState = {
  isMobile: false,
};
export class ShoppingItem extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.openLink = this.openLink.bind(this);
      this.removeFromList = this.removeFromList.bind(this);
    }
    
    openLink(e){
      window.open(this.props.item.sku_buy_url, '_blank');
    }

    removeFromList(){
      MakeupStore.removeFromList(this.props.item.sku_id);
    }
    
    componentDidMount() {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }

    resize() {
      let currentIsMobile = (window.outerWidth <= 990);
      if (currentIsMobile !== this.state.isMobile) {
          this.setState({isMobile: currentIsMobile});
      }
    }

    render(){
      var item = this.props.item;  
      if(item === null ) return (<div></div>);
      if(!this.state.isMobile){
        return(
            <CustomCard>
              <CustomIcon name='close' onClick={this.removeFromList}></CustomIcon>
              <CustomCardImg wrapped ui={false} src={item.sku_img_url} onClick={this.openLink}/>
                <Card.Content onClick={this.openLink}>
                  
                  <CustomCardHeader textAlign="left">
                    <div>{item.sku_name}</div>
                    <div className="shop-now-fake">SHOP NOW</div>
                  </CustomCardHeader>
                  <CustomCardMeta textAlign="left">{item.sku_id}</CustomCardMeta>
                  <CustomCardDesc textAlign="left">{item.sku_desc}</CustomCardDesc>
  
              </Card.Content>
              <Card.Content extra onClick={this.openLink}>
                <Statistic size="mini" floated="right">
                  <Statistic.Label>Price</Statistic.Label>
                  <Statistic.Value>{item.sku_price}</Statistic.Value>
                </Statistic>
                <Statistic size="mini" floated="left">
                  <Statistic.Label>Rating</Statistic.Label>
                  <Statistic.Value>
                    <Rating defaultRating={item.sku_rating} maxRating={5} disabled />
                  </Statistic.Value>
                </Statistic>
              </Card.Content>
            </CustomCard>
        );
      }
      else{
        return(
          <Item onClick={this.openLink}>
            <Item.Image size='tiny' src={item.sku_img_url} />
            <CustomItemContent>
              <Item.Header>{item.sku_name}</Item.Header>
              <Item.Meta>
                <span className='shopping-price'>{item.sku_price}</span>
              </Item.Meta>
              <Item.Description>                
                <span className='shopping-rating'><Rating defaultRating={item.sku_rating} maxRating={5} disabled /></span>
              </Item.Description>
              <Item.Extra>
                <div className="shop-now-fake shopping-now">SHOP NOW</div>
              </Item.Extra>
            </CustomItemContent>
          </Item>
        );
      }
    }
}
ShoppingItem = observer(ShoppingItem);
export default ShoppingItem;
