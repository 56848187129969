import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Grid, Segment, Card, Rating, Statistic, Icon, Image, Item } from 'semantic-ui-react'
import {ShoppingItem} from '.';
import AppStore from '../stores/AppStore';
import MakeupStore from '../stores/MakeupStore';

const ConstrainedSegment = styled(Segment)`
  margin: 30px 0px 0px 0px !important;
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  background: var(--shopping-cart-bg) !important;
`

const initialState = {
  isMobile: false,
};
export class ShoppingCart extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
    }

    componentDidMount() {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }

    resize() {
      let currentIsMobile = (window.outerWidth <= 990);
      if (currentIsMobile !== this.state.isMobile) {
          this.setState({isMobile: currentIsMobile});
      }
    }

    render(){
        if(!this.state.isMobile){
          return(
            <ConstrainedSegment>
              <Card.Group doubling stackable>
                {
                  MakeupStore.ShoppingCartMatchingProducts.map((item)=>{
                    return(
                        <ShoppingItem item={item}/>
                    );
                  })
                }
              </Card.Group>
            </ConstrainedSegment>
          );
        }
        else{
          return(
            <ConstrainedSegment>
              <Item.Group divided unstackable relaxed>
                {
                  MakeupStore.ShoppingCartMatchingProducts.map((item)=>{
                    return(
                        <ShoppingItem item={item}/>
                    );
                  })
                }
              </Item.Group>
            </ConstrainedSegment>
          );
        }
    }
}
ShoppingCart = observer(ShoppingCart);
export default ShoppingCart;
