import {decorate, observable, configure, action, computed} from "mobx";

const ApplicationType = parseInt(process.env.REACT_APP_ENV) || 1;
class AppStore {

  AppState = observable({
    Apps : Object.freeze({
      "BASIC": 1,
      "NUDEST" : 2,
      "ARTIST" : 3
    }),
    Pages : Object.freeze({
      "MAIN": 1,
      "LIVE" : 2,
      "PHOTO" : 3,
      "MODEL" : 4,
    }),
    showingApp : ApplicationType, // DEFAULT : BASIC
    showingPage : 1, // DEFAULT : MAIN
    multiApp: true, // DEFAULT : true
    showShoppingCart: true, // DEFAULT : false

    // more granular
    selectedModel: -1, // DEFAULT : -1
    uploadedImage: "", // DEFAULT : ""
    isSnappedPhoto: false, //DEFAULT : false

    //loader control
    showLoader: false, //DEFAULT : false
    // color panels reset
    looksReset: false,
    productsReset: false,
    // color heaviness
    clrHeavinessFactor: 1,

    //client config and themes
    clientCfg : Object.freeze({
      clientName: "Algoface.ai",
      clientHomeUrl : "https://algoface.ai",
      clientLogo: "/Assets/logo.png",
      clientBackgroundImage: "/Assets/vmt_basic_bg.png"
    })
  })

  get showShoppingCart () {return this.AppState.showShoppingCart;}

  get multiApp () {return this.AppState.multiApp;}
  
  get clientCfg() {return this.AppState.clientCfg;}

  get looksResetStatus() {return this.AppState.looksReset;}

  get productsResetStatus() {return this.AppState.productsReset;}

  get currentApp() {return this.AppState.showingApp;}

  get currentPage() {return this.AppState.showingPage;}

  get AppsEnum() {return this.AppState.Apps;}

  get PagesEnum() {return this.AppState.Pages;}

  get currentModel() {return this.AppState.selectedModel;}

  get currentImage() {return this.AppState.uploadedImage;}

  get LoaderStatus() {return this.AppState.showLoader;}

  get isSnappedPhoto() {return this.AppState.isSnappedPhoto;}

  get clrHeavinessFactor(){return this.AppState.clrHeavinessFactor;}

  setCurrentApp(value){this.AppState.showingApp = value;}

  setCurrentPage(value){this.AppState.showingPage = value;}

  setSelectedModel(value){this.AppState.selectedModel = value;}

  setUploadedImage(value){this.AppState.uploadedImage = value;}

  setClrHeavinessFactor(value){this.AppState.clrHeavinessFactor = value;}

  resetAppState(){
    this.AppState.showingPage = 1;
    this.AppState.selectedModel = -1;
    this.AppState.uploadedImage = "";
    this.AppState.showLoader = false;
    this.AppState.isSnappedPhoto = false;
  }

  setLoaderStatus(value){this.AppState.showLoader=value;}

  setIsSnappedPhoto(value){this.AppState.isSnappedPhoto=value;}

  setLooksResetStatus(value) {this.AppState.looksReset=value;}

  setProductsResetStatus(value) {this.AppState.productsReset=value;}
}

decorate(AppStore, {
  AppState : observable,
  setCurrentApp : action,
  setCurrentPage : action,
  setSelectedModel : action,
  setUploadedImage: action,
  setIsSnappedPhoto: action,
  resetAppState: action,
  setLoaderStatus: action,
  setLooksResetStatus: action,
  setProductsResetStatus: action,
  setClrHeavinessFactor: action,
  clientCfg : computed,
  AppsEnum : computed,
  PagesEnum : computed,
  currentApp : computed,
  currentPage : computed,
  currentModel: computed,
  currentImage: computed,
  LoaderStatus: computed,
  isSnappedPhoto: computed,
  looksResetStatus: computed,
  productsResetStatus: computed,
  clrHeavinessFactor : computed
})



export default new AppStore();
