import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Button } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';


const CustomButtonGroup = styled(Button.Group)`
  width: 100% !important;
` 

const initialState = {}
export class CategorySwitcher extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
    }

    render(){
        return(
          <CustomButtonGroup flex size='large'>
            <Button className={(this.props.showProductPage)?"category-switcher-btn-grp dev-selected":"category-switcher-btn-grp"} 
                    onClick={this.props.tryProductHandler}>TRY PRODUCTS</Button>
            <Button.Or className="category-switcher-or"/>
            <Button className={(!this.props.showProductPage)?"category-switcher-btn-grp dev-selected":"category-switcher-btn-grp"}
                    onClick={this.props.tryFullLookHandler}>TRY FULL LOOKS</Button>
          </CustomButtonGroup>
        );
    }
}
CategorySwitcher = observer(CategorySwitcher);
export default CategorySwitcher;
