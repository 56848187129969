import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Loader, Dimmer, Header, Icon } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';

const LoaderText = styled('div')`
  font-family: robo-bold !important;
  font-size: 1.3rem !important;
  color: var(--loader-txt-clr) !important;
`
const MAX_CHECKS_BEFORE_DEFAULT = 10;

const initialState = {
  activeText: "Loading ...",
  checks : MAX_CHECKS_BEFORE_DEFAULT
}
export class LoaderView extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.vmtlog = window.SERVICES.vmtlog;
      this.interval = -1;
    }

    componentDidMount(){
      var self = this;
      this.interval = setInterval(()=>{
        let vmtlogInfo = this.vmtlog.getInfo();

        if( vmtlogInfo == this.state.activeText){
          if(this.state.checks > 0){
            self.setState({checks:this.state.checks-1});
          }
          else{
            this.vmtlog.clear();
            self.setState(initialState);
          }
        }
        else{
          self.setState({activeText:vmtlogInfo})
        }
      },200);
    }
    componentDidUpdate(){
      if(!AppStore.LoaderStatus && this.interval != -1){
        clearInterval(this.interval);
        this.interval = -1;
      }
    }

    render(){
        return(
        <Dimmer active={AppStore.LoaderStatus}  page>
          <img className="loading-gif" src="/Assets/loading.gif"></img>
        </Dimmer>
        );
    }
}
LoaderView = observer(LoaderView);
export default LoaderView;
