import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Button, Segment, Container } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';

const ImageContainer = styled(Container)`
    height: inherit !important;
    width: inherit !important;
    background-repeat:no-repeat !important;
    background-size:contain !important;
    background-position:center !important;
`

const ImageContainer_basic = styled(ImageContainer)`
    background-image: url(${AppStore.clientCfg.clientBackgroundImage}) !important;
`
const ImageContainer_nudest = styled(ImageContainer)`
    background-image: url("/Assets/vmt_nudest_bg.png") !important;
`
const ImageContainer_artist = styled(ImageContainer)`
    background-image: url("/Assets/vmt_artist_bg.png") !important;
`

/**
 *********************************************
 ***    BASIC
 ********************************************* 
 */
class BackgroundImage_basic extends React.Component {
    render(){
        return(
            <ImageContainer_basic></ImageContainer_basic>
        );
    }
}

/**
 *********************************************
 ***    NUDEST
 ********************************************* 
 */
class BackgroundImage_nudest extends React.Component {
    render(){
        return(
            <ImageContainer_nudest></ImageContainer_nudest>
        );
    }
}

/**
 *********************************************
 ***    ARTIST
 ********************************************* 
 */
class BackgroundImage_artist extends React.Component {
    render(){
        return(
            <ImageContainer_artist></ImageContainer_artist>
        );
    }
}

class BackgroundImage extends React.Component {
    render(){
        if(AppStore.currentApp == AppStore.AppsEnum.BASIC) return(<BackgroundImage_basic/>);
        if(AppStore.currentApp == AppStore.AppsEnum.NUDEST)return(<BackgroundImage_nudest/>);
        if(AppStore.currentApp == AppStore.AppsEnum.ARTIST) return(<BackgroundImage_artist/>);
    }
}

BackgroundImage = observer(BackgroundImage);
export {BackgroundImage};