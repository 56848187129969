import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Button, Icon } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import ProductStore from '../stores/ProductStore';

const InputForUploadJSON = styled('input')`
    display: none !important;
`

const mandatory = ["SKU_ID", "SKU_NAME", "TYPE", "R", "G", "B"];
const blanks = ["", null, undefined, "NA", "na"]
const InitialStore = {
  "foundation": [],
  "blush": [],
  "highlighter": [],
  "lipstick": [],
  "bronzer":[],
  "contour": [],
  "lipliner":[],
  "eyeshadow": [],
  "eyelash": [],
  "eyeliner": [],
  "eyebrow": [],
}
const initialState = {}

export class JSONControlView extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.saveJSON = this.saveJSON.bind(this);
      this.loadJSON = this.loadJSON.bind(this);
      this.handleUploadJSONChange = this.handleUploadJSONChange.bind(this);
    }

    saveJSON(){
      var jsonStr = ProductStore.getJSON();
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(jsonStr);
      let link2 = document.createElement('a');
      let date = new Date();
      link2.download = "Algoface_"+date.toISOString().replace(".","_")+".json";
      link2.href = dataStr
      link2.click();
    }

    handleUploadJSONChange(selectedFiles){
      if(selectedFiles === null || !selectedFiles[0]) return;
      var reader = new FileReader();
      reader.readAsText(selectedFiles[0]);
      reader.onload = function() {
          if(selectedFiles[0].type.indexOf("csv") != -1){
            var Store = InitialStore;
            var lines = reader.result.split("\n");
            var idxMapping = {}
            var heading = lines[0].split(",")
            idxMapping["SKU_ID"] = heading.indexOf("SKU_ID");
            idxMapping["SKU_NAME"] = heading.indexOf("SKU_NAME");
            idxMapping["TYPE"] = heading.indexOf("TYPE");
            idxMapping["R"] = heading.indexOf("R");
            idxMapping["G"] = heading.indexOf("G");
            idxMapping["B"] = heading.indexOf("B");
            idxMapping["A"] = heading.indexOf("A");
            idxMapping["LIP_EFFECT"] = heading.indexOf("LIP_EFFECT");
            idxMapping["LIP_EFFECT_INTENSITY"] = heading.indexOf("LIP_EFFECT_INTENSITY");

            idxMapping["GLITTER_MASK"] = heading.indexOf("GLITTER_MASK");
            idxMapping["GLITTER_INTENSITY"] = heading.indexOf("GLITTER_INTENSITY");
            idxMapping["SHIMMER_MASK"] = heading.indexOf("SHIMMER_MASK");
            idxMapping["SHIMMER_INTENSITY"] = heading.indexOf("SHIMMER_INTENSITY");

            idxMapping["MASK"] = heading.indexOf("MASK");
            


            // verify
            for( let csvCols in idxMapping){
              if(idxMapping[csvCols] == -1){
                if (mandatory.indexOf(csvCols) != -1){
                  window.SERVICES.notify(`csv should have ${mandatory.join(", ")} columns`,"ERR");
                  ProductStore.loadFromJSON(JSON.stringify(InitialStore));
                  return;
                }
                else{
                  window.SERVICES.notify(`be aware, '${csvCols}' column is missing`,"WARN");
                }
              }
            }

            for(let i = 1; i < lines.length; i++){
              try{
                var cells = lines[i].split(',');
                let product = {};
                product.idx = Store[cells[idxMapping["TYPE"]].toLowerCase()].length;
                product.sku_id = cells[idxMapping["SKU_ID"]];
                product.sku_name = cells[idxMapping["SKU_NAME"]];
                product.type = cells[idxMapping["TYPE"]];
                product.r = parseInt(cells[idxMapping["R"]]);
                product.g = parseInt(cells[idxMapping["G"]]);
                product.b = parseInt(cells[idxMapping["B"]]);
                product.a = (idxMapping["A"] != -1) ? parseFloat(cells[idxMapping["A"]]): 1.0

                if(cells[idxMapping["TYPE"]].toLowerCase() === "lipstick"){
                  
                  if(idxMapping["LIP_EFFECT"] != -1 && blanks.indexOf(cells[idxMapping["LIP_EFFECT"]]) == -1){
                    if(cells[idxMapping["LIP_EFFECT"]].toUpperCase() == "MATTE"){
                      product.finish_type = 0;
                    }
                    else if(cells[idxMapping["LIP_EFFECT"]].toUpperCase() == "SATIN"){
                      product.finish_type = 1;
  
                    } 
                    else if(cells[idxMapping["LIP_EFFECT"]].toUpperCase() == "GLOSS"){
                      product.finish_type = 2;
                    } 
                    else{
                      console.warn(`skipped line with index ${i}, cause : unknown lip effect`);
                      continue;
                    }
                  } 
                  else{
                    product.finish_type = 0;
                  }
  
                  if(idxMapping["LIP_EFFECT_INTENSITY"] != -1 && blanks.indexOf(cells[idxMapping["LIP_EFFECT_INTENSITY"]]) == -1){
                    try{
                      product.finish_intensity = parseFloat(cells[idxMapping["LIP_EFFECT_INTENSITY"]]);
                      if(product.finish_intensity < 0 && product.finish_intensity > 1 ) throw "not in range";
                    }
                    catch(err){
                      console.warn(`skipped line with index ${i}, cause : ${err}`);
                      continue;
                    }
                  } 
                  else{
                    product.finish_intensity = 0;
                  }
                }

                if(["blush", "highlighter", "bronzer", "contour", "lipliner","eyelash", "eyeshadow", "eyeliner", "eyebrow"].indexOf(cells[idxMapping["TYPE"]].toLowerCase()) != -1){
                  if(idxMapping["MASK"] != -1 && blanks.indexOf(cells[idxMapping["MASK"]]) == -1){
                    product.mask = cells[idxMapping["MASK"]];
                    if(typeof(product.mask) !== "string"){
                      console.warn(`skipped line with index ${i}, cause : unknown mask`);
                      continue;
                    }
                  }
                  else{
                    product.mask =  `${cells[idxMapping["TYPE"]].toLowerCase()}_1`
                  }
                }
                
                /** GLITTER */
                if(["lipstick"].indexOf(cells[idxMapping["TYPE"]].toLowerCase()) != -1){
                  if(idxMapping["GLITTER_MASK"] != -1 && blanks.indexOf(cells[idxMapping["GLITTER_MASK"]]) == -1 &&
                  idxMapping["GLITTER_INTENSITY"] != -1 && blanks.indexOf(cells[idxMapping["GLITTER_INTENSITY"]]) == -1){
                    product.glitter_mask = cells[idxMapping["GLITTER_MASK"]];
                    try{
                      product.glitter_intensity = parseFloat(cells[idxMapping["GLITTER_INTENSITY"]]);
                    }
                    catch(e){
                      console.warn(`error occurred casting glitter intensity to float. err=${e}`);
                      continue;
                    }
                    if(typeof(product.glitter_mask) !== "string"){
                      console.warn(`skipped line with index ${i}, cause : unknown glitter mask`);
                      continue;
                    }
                    if(typeof(product.glitter_intensity) !== "number"){
                      console.warn(`skipped line with index ${i}, cause : unknown glitter intensity`);
                      continue;
                    }
                  }
                }

                /** SHIMMER */
                if(["lipstick", "blush", "highlighter", "eyeshadow"].indexOf(cells[idxMapping["TYPE"]].toLowerCase()) != -1){
                  if(idxMapping["SHIMMER_MASK"] != -1 && blanks.indexOf(cells[idxMapping["SHIMMER_MASK"]]) == -1 &&
                  idxMapping["SHIMMER_INTENSITY"] != -1 && blanks.indexOf(cells[idxMapping["SHIMMER_INTENSITY"]]) == -1){
                    product.shimmer_mask = cells[idxMapping["SHIMMER_MASK"]];
                    try{
                      product.shimmer_intensity = parseFloat(cells[idxMapping["SHIMMER_INTENSITY"]]);
                    }
                    catch(e){
                      console.warn(`error occurred casting shimmer intensity to float. err=${e}`);
                      continue;
                    }
                    if(typeof(product.shimmer_mask) !== "string"){
                      console.warn(`skipped line with index ${i}, cause : unknown shimmer mask`);
                      continue;
                    }
                    if(typeof(product.shimmer_intensity) !== "number"){
                      console.warn(`skipped line with index ${i}, cause : unknown shimmer intensity`);
                      continue;
                    }
                  }
                }

                
                
                Store[cells[idxMapping["TYPE"]].toLowerCase()].push(product);
              }
              catch(err){
                console.warn(`skipped line with index ${i}, cause : ${err}`);
              }
            }
            ProductStore.loadFromJSON(JSON.stringify(Store));
            window.SERVICES.notify(`CSV loaded successfully`,"INFO");
          }
          else if(selectedFiles[0].type.indexOf("json") != -1){
            ProductStore.loadFromJSON(reader.result);
            window.SERVICES.notify(`JSON loaded successfully`,"INFO");
          }
          else{
            window.SERVICES.notify(`unknown file format expected .json or .csv, try again`,"ERR");
            ProductStore.loadFromJSON(JSON.stringify(InitialStore));
            return;
          }
      }
    }

    loadJSON(){
      document.getElementById('input-elm-for-upload-json').click();
    }

    render(){
        return(
          <div>
            <InputForUploadJSON type="file" id="input-elm-for-upload-json" onClick={(e)=> {e.target.value = null}} onChange={(e) =>{this.handleUploadJSONChange(e.target.files)}}/>
            <Button onClick={this.loadJSON} size='small'  icon='upload' content='Upload Catalog' />
            <Button onClick={this.saveJSON} size='small'  icon='save' content='Save Catalog' />
          </div>
        );
    }
}
JSONControlView = observer(JSONControlView);
export default JSONControlView;
