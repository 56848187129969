import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Accordion, Icon } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import MakeupStore from '../stores/MakeupStore';
import {Carousel, NudestFoundationCarousel} from '.';



const CustomAccordion = styled(Accordion)`
  background: var(--accordion-bg) !important;
  box-shadow: unset !important;
`
const CustomAccItemTitle = styled(Accordion.Title)`
  display: inline-flex !important;
  width: 100% !important;
`
const CustomAccItemDropdownIcon = styled(Icon)`
  margin: auto 1% auto auto !important;
`

const CustomAccItemContainer = styled('div')`
  display: inline-flex !important;
`
const CustomAccItemIndicator = styled('img')`
  width: 40px !important;
  float: left !important;
  height: 40px !important;
  margin: auto !important;
`
const CustomAccItemHeadText = styled('div')`
  font-family: robo-reg !important;  
  margin:auto 10px auto 10px !important;
  color: var(--accordion-txt-clr-A) !important;
  &:hover{
    color:var(--accordion-txt-clr-B) !important;
  }
`

const CustomAccItemCarouselItemInfo = styled('div')`
  display: inline-flex !important;
  margin:auto 10px auto 10px !important;
  color: var(--accordion-txt-clr-A) !important;
  &:hover{
    color:var(--accordion-txt-clr-B) !important;
  }
`
const CustomAccItemCarouselItemInfoName = styled('div')`
  font-style: italic !important;
  font-family: robo-thin !important;
  font-size: 1.0rem !important;
  margin: auto !important;
  color: var(--accordion-txt-clr-A) !important;
  &:hover{
    color:var(--accordion-txt-clr-B) !important;
  }
`
const CustomAccItemCarouselItemInfoImage = styled('img')`
  width: auto;
  float: left !important;
  height: 45px;
  margin:auto 10px auto 10px !important;
  border-radius: 7px;
  background: var(--accordion-carousel-mask-item-bg) !important;
`

const AccordionItem = (props) => {
  return(
    <CustomAccItemContainer>
      <CustomAccItemIndicator className='product-colo-menu-img accordion-product-category-img-icons' src={'/Assets/'+props.itemTitle.toLowerCase() +'.png'}></CustomAccItemIndicator>
      <CustomAccItemHeadText> SELECT {props.itemTitle.toUpperCase()} </CustomAccItemHeadText>
      { props.carouselItem && (!props.carouselItem.hasOwnProperty('length')) &&
        <CustomAccItemCarouselItemInfo>
            { false &&            
              <CustomAccItemCarouselItemInfoName>
                {props.carouselItem.sku_name.toLowerCase()}
              </CustomAccItemCarouselItemInfoName>
            }
            { props.carouselItem.mask &&
              <CustomAccItemCarouselItemInfoImage className='product-colo-menu-mask-img' src={`/Masks/${props.carouselItem.mask}.png`}>
              </CustomAccItemCarouselItemInfoImage>
            }
        </CustomAccItemCarouselItemInfo>
      }
      { props.carouselItem && (props.carouselItem.hasOwnProperty('length')) &&
        <CustomAccItemCarouselItemInfo>
            { false &&
              <CustomAccItemCarouselItemInfoName>
                {props.carouselItem[0].sku_name.toLowerCase()}
              </CustomAccItemCarouselItemInfoName>
            }
            {
              props.carouselItem.map((item,index)=>{
                return(
                  <CustomAccItemCarouselItemInfoImage className='product-colo-menu-mask-img' src={`/Masks/${item.mask}.png`}>
                  </CustomAccItemCarouselItemInfoImage>
                )
              })
            }
        </CustomAccItemCarouselItemInfo>
      }
    </CustomAccItemContainer>
  )
}

const initialState = {

  activeIndex: 0,
  carouselItem: null

}
export class ProductsMenu extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.setCarouselItem = this.setCarouselItem.bind(this);
    }

    handleClick = (e, titleProps) => {
      const { index } = titleProps;
      const { activeIndex } = this.state;
      const newIndex = activeIndex === index ? -1 : index;
      this.setState({ activeIndex: newIndex });
    }

    setCarouselItem(item){
      this.setState({carouselItem:item});
    }

    componentDidMount(){
    }

    render(){
        
        const { activeIndex } = this.state;

        return(
          <CustomAccordion fluid styled>
            {
              (MakeupStore.foundation.length > 0) && 
              <CustomAccItemTitle className={`${(activeIndex === 0) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 0} index={0} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 0)?this.state.carouselItem:null} itemTitle="Foundation"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.foundation.length > 0) &&
              <Accordion.Content active={activeIndex === 0}>
                {
                  (AppStore.currentApp === AppStore.AppsEnum.NUDEST) &&
                  <NudestFoundationCarousel setCarouselItem={this.setCarouselItem} data={MakeupStore.foundation}/>
                }
                {
                  !(AppStore.currentApp === AppStore.AppsEnum.NUDEST) &&
                    <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.foundation}/>
                }
              </Accordion.Content>
            } 

{
              (MakeupStore['foundation2'].length > 0) && 
              <CustomAccItemTitle className={`${(activeIndex === 11) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 11} index={11} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 11)?this.state.carouselItem:null} itemTitle="Foundation 2"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore['foundation2'].length > 0) &&
              <Accordion.Content active={activeIndex === 11}>
                {
                  (AppStore.currentApp === AppStore.AppsEnum.NUDEST) &&
                  <NudestFoundationCarousel setCarouselItem={this.setCarouselItem} data={MakeupStore['foundation2']}/>
                }
                {
                  
                  !(AppStore.currentApp === AppStore.AppsEnum.NUDEST) &&
                    <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore['foundation2']}/>
                }
              </Accordion.Content>
            }
              
            {
              (MakeupStore.lipstick.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 1) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 1} index={1} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 1)?this.state.carouselItem:null}  itemTitle="Lipstick"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.lipstick.length > 0) &&
              <Accordion.Content active={activeIndex === 1}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.lipstick}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.lipliner.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 2) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 2} index={2} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 2)?this.state.carouselItem:null}  itemTitle="Lipliner"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.lipliner.length > 0) &&
              <Accordion.Content active={activeIndex === 2}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.lipliner}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.blush.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 3) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 3} index={3} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 3)?this.state.carouselItem:null}  itemTitle="Blush"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.blush.length > 0) &&
              <Accordion.Content active={activeIndex === 3}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.blush}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.highlighter.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 4) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 4} index={4} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 4)?this.state.carouselItem:null}  itemTitle="Highlighter"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.highlighter.length > 0) &&
              <Accordion.Content active={activeIndex === 4}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.highlighter}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.bronzer.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 5) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 5} index={5} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 5)?this.state.carouselItem:null}  itemTitle="Bronzer"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.bronzer.length > 0) &&
              <Accordion.Content active={activeIndex === 5}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.bronzer}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.contour.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 6) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 6} index={6} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 6)?this.state.carouselItem:null}  itemTitle="Contour"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.contour.length > 0) &&
              <Accordion.Content active={activeIndex === 6}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.contour}/>
              </Accordion.Content>
            }


            {
              (MakeupStore.eyeliner.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 9) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 9} index={9} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 9)?this.state.carouselItem:null}  itemTitle="Eyeliner"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.eyeliner.length > 0) &&
              <Accordion.Content active={activeIndex === 9}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.eyeliner}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.eyelash.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 7) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 7} index={7} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 7)?this.state.carouselItem:null}  itemTitle="Eyelash"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.eyelash.length > 0) &&
              <Accordion.Content active={activeIndex === 7}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.eyelash}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.eyeshadow.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 8) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 8} index={8} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 8)?this.state.carouselItem:null}  itemTitle="Eyeshadow"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.eyeshadow.length > 0) &&
              <Accordion.Content active={activeIndex === 8}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.eyeshadow}/>
              </Accordion.Content>
            }

            {
              (MakeupStore.eyebrow.length > 0) &&
              <CustomAccItemTitle className={`${(activeIndex === 10) ? 'products-menu-title-height-active':'products-menu-title-height-inactive'}`}  active={activeIndex === 10} index={10} onClick={this.handleClick}>
                <AccordionItem carouselItem={(activeIndex === 10)?this.state.carouselItem:null}  itemTitle="Eyebrow"/>
                <CustomAccItemDropdownIcon name='dropdown' />
              </CustomAccItemTitle>
            }
            {
              (MakeupStore.eyebrow.length > 0) &&
              <Accordion.Content active={activeIndex === 10}>
                <Carousel setCarouselItem={this.setCarouselItem} data={MakeupStore.eyebrow}/>
              </Accordion.Content>
            }
            
          </CustomAccordion>
        );
    }
}
ProductsMenu = observer(ProductsMenu);
export default ProductsMenu;
