import React, { Component } from 'react';
import './App.css';
import { AppContainer } from './containers';


class App extends Component {

  render() {
    return (
        <AppContainer/>
    );
  }
}

export default App