import * as React from 'react';
import styled from '@emotion/styled';
import {LoaderView} from '.'
import {observer} from 'mobx-react';
import { Button, Segment, Icon, Card } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import $ from 'jquery';
import 'jqueryui';

const CustomContainer = styled(Segment)`
  margin: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
`

const CustomButtonGroup = styled('div')`
  text-align: center !important;
`
const CustomButton = styled(Button)`
  position:relative !important;
  z-index:10 !important;
`
const SimpleButton2 = styled(CustomButton)`
  position: absolute !important;
  top: 1%;
  left: 1%;
  padding: 0.1em 0.2em;
  background: unset !important;
`

const CloseButton = styled(CustomButton)`
  position: absolute !important;
  top: 1%;
  right: 1%;
`
const CustomCanvas = styled('canvas')`
  display : none;
`
const CustomCard = styled(Card)`
  margin: auto !important;
  width: fit-content !important;
  position: relative !important;
`
const EmptySplitterContainer = styled('div')``

const SplitterHandleBarContainer = styled('div')`
  position: absolute;
  width: 20px;
  height: 100%;
  background: rgba(255, 0, 0, 0);
  left: calc(50% - 13px);
  top: 0px;
  z-index: 4;
`
const SplitterHandleBarContainerSlider = styled('div')`
  width: 4px;
  height: 100%;
  background: #fff;
  margin: auto;
  z-index: 3;
`
const SplitterHandleBarContainerHandleIcon = styled('div')`
  position: absolute;
  height: 50px;
  width: 20px;
  top:calc(70%);
  left: calc(50% - 10px);
  background-size: 28px;
  background-repeat: no-repeat;
  background-image: url("/Assets/splitter_b.png");
  background-position: center;
  background-color: var(--live-view-splitter-pole-handle-bg);
  border-radius: 3px;
  cursor: grabbing;
  z-index: 3;
`
const SplitterImageContainer = styled('div')`
  background-repeat: no-repeat;
  height: fit-content;
  width: 50%;
  position: absolute !important;
  overflow: hidden !important;
  `


const MAX_ZOOM = 5;

const initialState = {
  zoomValue : 0,
  isSplitterActive : false,
  hasMultiCamera : false,
}

export class LiveViewArtist extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState
      this.resetViewParams = this.resetViewParams.bind(this);
      this.zoomIn = this.zoomIn.bind(this);
      this.zoomOut = this.zoomOut.bind(this);
      this.downloadImage = this.downloadImage.bind(this);
      this.toggleSplitter = this.toggleSplitter.bind(this);
      this.noFaceDetectionHandle = this.noFaceDetectionHandle.bind(this);
    }


    zoomIn(){
      if(this.state.zoomValue >= 0 && this.state.zoomValue < MAX_ZOOM){
        let newZoomValue = this.state.zoomValue + 1;
        /** set state */
        this.setState({zoomValue: newZoomValue});
        /** reflect this zoom value update in vmtcore instance */
        this.vmtCoreController.zoomTo(newZoomValue);

        window.vmt_module._setModelImageBypass(this.props.model);
        this.vmtCoreController.resetDetection();
        this.vmtCoreController.getDetection();
        this.vmtCoreController.render();
        $(`#splitter-image-container_model_${this.props.model}`).css({
          "background-image" : `url(${this.vmtCoreController.getSplitterImageB64()})`,
          "background-size" : `${this.vmtCoreController.getFinalCanvas().clientWidth}px ${this.vmtCoreController.getFinalCanvas().clientHeight}px`
        });
      }
    }

    resetViewParams(){
      /**
       * remove all makeup
       * reset zoom to '0'
       * render updates if necessary
       */
      // AppStore.setLooksResetStatus(true);
      // AppStore.setProductsResetStatus(true);
      window.vmt_module._resetAllMakeup();
      this.vmtCoreController.zoomTo(0);
      this.setState({zoomValue: 0});
      if(this.state.isSplitterActive) this.toggleSplitter();
      window.vmt_module._setModelImageBypass(this.props.model);
      this.vmtCoreController.resetDetection();
      this.vmtCoreController.getDetection();
      this.vmtCoreController.render();
      AppStore.setLooksResetStatus(true);
      AppStore.setProductsResetStatus(true);
      $(`#splitter-image-container_model_${this.props.model}`).css({
        "background-image" : `url(${this.vmtCoreController.getSplitterImageB64()})`,
        "background-size" : `${this.vmtCoreController.getFinalCanvas().clientWidth}px ${this.vmtCoreController.getFinalCanvas().clientHeight}px`
      });
    }

    zoomOut(){
      if(this.state.zoomValue >0 && this.state.zoomValue <= MAX_ZOOM){
        let newZoomValue = this.state.zoomValue - 1;
        /** set state */
        this.setState({zoomValue: newZoomValue});
        /** reflect this zoom value update in vmtcore instance */
        this.vmtCoreController.zoomTo(newZoomValue);

        window.vmt_module._setModelImageBypass(this.props.model);
        this.vmtCoreController.resetDetection();
        this.vmtCoreController.getDetection();
        this.vmtCoreController.render();
        $(`#splitter-image-container_model_${this.props.model}`).css({
          "background-image" : `url(${this.vmtCoreController.getSplitterImageB64()})`,
          "background-size" : `${this.vmtCoreController.getFinalCanvas().clientWidth}px ${this.vmtCoreController.getFinalCanvas().clientHeight}px`
        });
      }
    }

    noFaceDetectionHandle(){
      if(!this.vmtCoreController.getIsDetected()){
        var self = this;
        window.SERVICES.notify("Face not detected, returning in 5 sec.", "ERR");
        setTimeout(()=>{
          self.returnToMainMenu();
        },5000);
      }
    }

    toggleSplitter(){
      var self = this;
      let newSplitterState = !this.state.isSplitterActive;
      /** update state */
      this.setState({ isSplitterActive: newSplitterState});
      window.vmt_module._setModelImageBypass(this.props.model);
      this.vmtCoreController.resetDetection();
      this.vmtCoreController.getDetection();
      this.vmtCoreController.render();

      if(newSplitterState){
        /** splitter is now active */

        /** indicate vmtcore about splitter state */
        this.vmtCoreController.setIsSplitterActive(true);

        if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
          /** attach base canvas to splitter-image-container and make it visible */
          document.getElementById(`canvas_splitter_model_${self.props.model}`).style.display = "none";
          document.getElementById(`canvas_base_model_${self.props.model}`).style.display = "block";
        }
        else{
          /** render splitter image on splitter-image-container */
          document.getElementById(`canvas_splitter_model_${self.props.model}`).style.display = "block";
          document.getElementById(`canvas_base_model_${self.props.model}`).style.display = "none";
        }

        

        /** attach draggable feature */
        var handleBarID = `#handlebar_model_${self.props.model}`
        $( handleBarID ).draggable({
          axis: "x",
          containment: "parent",
          snap:true,
          snapTolerance: 30,
          drag: function() {
            $(`#splitter-image-container_model_${self.props.model}`).css("width","calc("+$( handleBarID ).css("left")+" + 13px)");
          },
          start: function() {
            $(`#splitter-image-container_model_${self.props.model}`).css("width","calc("+$( handleBarID ).css("left")+" + 13px)");
          },
          stop: function() {
            $(`#splitter-image-container_model_${self.props.model}`).css("width","calc("+$( handleBarID ).css("left")+" + 13px)");
          },
         });
         window.touchInit(`handlebar_model_${self.props.model}`);
      }
      else{
        /** indicate vmtcore about splitter state */
        this.vmtCoreController.setIsSplitterActive(false);
        // window.touchDeInit();
      }
      
    }

    downloadImage(){
      let vc = window.SERVICES.vmtCoreCollection[this.props.model];
     
      let backCanvas = document.createElement('canvas');
      backCanvas.width = vc.canvas_o.width;
      backCanvas.height = vc.canvas_o.height;
      let backCtx = backCanvas.getContext('2d');
      backCtx.drawImage(vc.canvas_o, 0,0);

      // draw base image if splitter is active
      if(this.state.isSplitterActive){
        let clientWidthToCover = document.getElementById(`splitter-image-container_model_${this.props.model}`).clientWidth;
        let widthToCover = (clientWidthToCover * vc.canvas_o.width)/ vc.canvas_o.clientWidth;
        let refCanvas = (AppStore.currentPage == AppStore.PagesEnum.LIVE)? vc.canvas_i : vc.canvas_splitter;
        backCtx.drawImage(refCanvas, 0, 0, widthToCover, vc.canvas_o.height, 0, 0, widthToCover, vc.canvas_o.height);
        backCtx.fillStyle = 'white';
        backCtx.rect((widthToCover-2), 0, 4, vc.canvas_o.height);
        backCtx.fill();
      }
      
      backCtx.fillStyle = '#af9a7f';
      backCtx.font = "14px robo-bold";
      backCtx.fillText("ALGOFACE", 5 , vc.canvas_o.height - 5);
  
      let link = document.createElement('a');
      let date = new Date();
      link.download = "Algoface_"+date.toISOString().replace(".","_")+".png";
      link.href = backCanvas.toDataURL("image/png");
      link.click();
      window.SERVICES.notify(`Downloading image.`);
    }

    liveViewInit(){
      /**
       * remove all makeup
       * reset zoom to '0'
       * render updates if necessary
       */
      window.vmt_module._resetAllMakeup();
      try{
        this.vmtCoreController.zoomTo(0);
        this.setState({zoomValue: 0});
      }
      catch(error){
        console.warn(`[ERR] unknown error at liveViewInit ${error}`);
      }

      //load respective model
      let imageURI = `/Models/${this.props.model}.jpg`;
      var self = this;
      this.imageController = new window.SERVICES.imageClass();
      this.vmtCoreController = new window.SERVICES.vmtcoreClass();
      if (!window.SERVICES.vmtCoreCollection) window.SERVICES.vmtCoreCollection = {};
      window.SERVICES.vmtCoreCollection[this.props.model] = this.vmtCoreController;
      this.imageController.registerDrawCb(
        /** register a callback that runs on image load */
        function(){
          window.vmt_module._setMaxIterations(7);
          self.vmtCoreController.setSplitterCanvasId(`canvas_splitter_model_${self.props.model}`)
          self.vmtCoreController.init(`canvas_base_model_${self.props.model}`,`canvas_final_model_${self.props.model}`);
          self.vmtCoreController.setIsLive(false);
          self.vmtCoreController.render();
        }
      );
      this.imageController.init(`canvas_base_model_${this.props.model}`,imageURI);

      AppStore.setLoaderStatus(false);
    }

    componentDidMount(){
      /** start loading page */
      AppStore.setLoaderStatus(true);
      
      /** wait for vmt module to load completely */
      var self = this;
      this.interval = setInterval(()=>{
        if(window.vmt_module && window.vmt_module.hasOwnProperty('_isVmtReady') && window.vmt_module._isVmtReady()){
          self.liveViewInit();
          clearInterval(self.interval);
        }
      },500);

      /** enable/disable multi camera button */
      window.SERVICES.video.getActiveDevices().then((devices)=>{
        if (devices.length > 1){
          self.setState({hasMultiCamera:true})
        }
        else{
          self.setState({hasMultiCamera:false})
        }
      })
    }

    render(){
        return(
          <CustomContainer>
            <LoaderView/>
            <CustomCard id={`viewport_card_model_${this.props.model}`} className={(this.props.isSolo)?``:`viewport_card_model`}>
              <EmptySplitterContainer style={{display:`${(this.state.isSplitterActive)?'block':'none'}`}}>
                <SplitterHandleBarContainer id={`handlebar_model_${this.props.model}`}>
                  <SplitterHandleBarContainerSlider></SplitterHandleBarContainerSlider>
                  <SplitterHandleBarContainerHandleIcon></SplitterHandleBarContainerHandleIcon>
                </SplitterHandleBarContainer>
                <SplitterImageContainer className={`${(AppStore.currentPage === AppStore.PagesEnum.LIVE)?'canvas-container-fit-content':'canvas-container-100-percent'}`} id={`splitter-image-container_model_${this.props.model}`}>
                  <CustomCanvas id={`canvas_splitter_model_${this.props.model}`}></CustomCanvas>
                  <CustomCanvas id={`canvas_base_model_${this.props.model}`}></CustomCanvas>
                </SplitterImageContainer>

              </EmptySplitterContainer>
              <canvas id={`canvas_final_model_${this.props.model}`}></canvas>
              <Card.Content>
                <CustomButtonGroup>
                  <CustomButton title="zoom in" icon onClick={this.zoomIn}><Icon name='zoom-in' /></CustomButton>
                  <CustomButton title="zoom out" icon onClick={this.zoomOut}><Icon name='zoom-out' /></CustomButton>
                  <CustomButton title="toggle on/off image splitter" toggle icon active={this.state.isSplitterActive} onClick={this.toggleSplitter}><Icon name='adjust' /></CustomButton>
                  {
                    (AppStore.currentPage === AppStore.PagesEnum.LIVE) && this.state.hasMultiCamera &&
                    <SimpleButton2 title="toggle camera" icon onClick={()=>window.SERVICES.video.toggleCamera()}><img className="camera-toggle-icon" src="https://img.icons8.com/ios-filled/50/000000/switch-camera.png"/></SimpleButton2>
                  }
                  <CustomButton title="download image" icon onClick={this.downloadImage}><Icon name='download' /></CustomButton>
                </CustomButtonGroup>
              </Card.Content>
            </CustomCard>
          </CustomContainer>
        );
    }
}

LiveViewArtist = observer(LiveViewArtist);
export default LiveViewArtist;
