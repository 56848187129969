import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Segment, Button, Header, Accordion, Icon } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import MakeupStore from '../stores/MakeupStore';
import {Carousel} from '.';

const CustomAccordion = styled(Accordion)`
  background: var(--accordion-bg) !important;
  box-shadow: unset !important;
`

const CustomAccItemTitle = styled(Accordion.Title)`
  display: inline-flex !important;
  width: 100% !important;
`

const CustomAccItemDropdownIcon = styled(Icon)`
  margin: auto 1% auto auto !important;
`

const CustomAccItemContainer = styled('div')`
  display: inline-flex !important;
`

const CustomAccItemHeadText = styled('div')`
  font-family: robo-reg !important;  
  margin:auto 0px auto 10px !important;
  color: var(--accordion-txt-clr-A) !important;
  &:hover{
    color: var(--accordion-txt-clr-B) !important
  }
`
const CustomSectionContainer = styled(Segment)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  display: flex !important;
  position: relative !important;
  background: var(--accordion-carousel-bg) !important;
`

const CustomSegmentContainer = styled(Segment.Group)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  width: 95% !important;
  margin: 0px 2.5% !important;
  background-color: var(--accordion-carousel-fg) !important;
  height: 100px !important;
  overflow-x: hidden;
`
const CustomSegmentContainerMoving = styled(Segment.Group)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  height: inherit !important;
  position: absolute !important;
  top: 0px;
  left : 0px;
  -webkit-transition: left 0.8s; /* Safari */
  transition: left 0.8s;
`
const CustomHorizontalSegment = styled(Segment)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  margin: 0px 15px !important;
  display: flex !important;
`

const CustomCarouselItemContainer = styled('div')`
  border-radius: 8px !important;
  background-size: contain;
  width: 90px !important;
  height: 90px !important;
  background-repeat: no-repeat;
  margin: auto !important;
  cursor: pointer !important;
  &:hover{
    border: 2px solid var(--accordion-carousel-item-border-hover) !important;
  }
`
const CustomButton = styled(Button)`
  color: var(--accordion-carousel-move-btn-txt-clr) !important;
  background: var(--accordion-carousel-move-btn-bg) !important;
  &:hover{
    color: var(--accordion-carousel-move-btn-txt-clr-hover) !important;
    background: var(--accordion-carousel-move-btn-bg-hover) !important;
  }
`

const AccordionItem = (props) => {
  return(
    <CustomAccItemContainer>
      <CustomAccItemHeadText> SELECT {props.itemTitle.toUpperCase()} </CustomAccItemHeadText>
    </CustomAccItemContainer>
  )
}

const CheckerPattern = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAALElEQVQYV2P8////fwYkwMjIKM2ILAgSAMnDBWECcEFkAbAgAwODFLKZIDYAiyYQiKtJnA0AAAAASUVORK5CYII="

const initialState = {
  scrollVal: 10,
  selected : -1,

}
export class LooksMenu extends React.Component {

    constructor(props){
      super(props);
      this.state = initialState;
      this.handleMoveLeft = this.handleMoveLeft.bind(this);
      this.handleMoveRight = this.handleMoveRight.bind(this);
      this.resetCarousel = this.resetCarousel.bind(this);
    }

    handleMakeup(evt, idx){

      /** rest makeup element */
      let remove = false;
      if( idx === -1){
        remove = true;
      }

      
      window.vmt_module._resetAllMakeup();
      this.setState({selected:idx});
      
      if(remove && !window.SERVICES.vmtcore.getIsLive()){
        window.SERVICES.vmtcore.render();
      } 
        

      
      if(idx == 1){
        // window.SERVICES.vmtcore.applyFoundation(241, 193, 171, 0.225);
        window.SERVICES.vmtcore.applyLipstick(237, 99, 17, 0.4,1,'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_1", 25, 25, 25, 0.25);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_1", 33, 35, 35, 0.25);
      }

      if(idx == 2){
        // window.SERVICES.vmtcore.applyFoundation(241, 193, 171, 0.225);
        
        window.SERVICES.vmtcore.applyBlush("blush_3", 217, 146, 117, 0.12,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",228,211,182,0.33);
        window.SERVICES.vmtcore.applyLipstick(203, 62, 106, 0.6392156862745098, 1, 'lipGlitter_2',0,'lipShimmer_1',0.15);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_1", 133, 73, 46, 0.35,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_2", 25, 25, 25, 0.45);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_2", 35, 35, 35, 0.45);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 103, 77, 63, 0.55);
      }

      if(idx == 3){
        // window.SERVICES.vmtcore.applyFoundation(241, 193, 171, 0.225);
        window.SERVICES.vmtcore.applyBlush("blush_2", 191, 82, 42, 0.0784313725490196,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyBronzer('bronzer_fullface',81,70,60,0.44);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",228,211,182,0.33);
        window.SERVICES.vmtcore.applyLipstick(188, 136, 109, 0.67, 2, 'lipGlitter_2',0,'lipShimmer_1',0.1);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_2", 150, 93, 69, 0.4,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_3", 0, 0, 0, 0.55);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_6", 246, 225, 189, 0.33);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder_5", 167, 133, 106, 0.94);
      }

      if(idx == 4){
        window.SERVICES.vmtcore.applyFoundation(193, 156, 101, 0.1411764705882353,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlender(241,193,171,0.3);
        window.SERVICES.vmtcore.applyBlush("blush_2", 146, 93, 62, 0.1,'blush_shimmer',0.3);
        window.SERVICES.vmtcore.applyLipliner('lipliner_2',191,101,101,0.75);
        window.SERVICES.vmtcore.applyBronzer('bronzer_fullface',56,49,42,0.3);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",231,214,184,0.2);
        window.SERVICES.vmtcore.applyLipstick(246, 145, 145, 0.6901960784313725, 0, 'lipGlitter_2',0,'lipShimmer_1',0.15);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_5", 127, 81, 107, 0.5686274509803921,'eyeshadow_shimmer',0.3);
        window.SERVICES.vmtcore.applyEyelash("eyelash_4", 25, 25, 25, 0.55);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_3", 33, 35, 35, 0.35);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 146, 108, 77, 0.5019607843137255);
      }

      if(idx == 5){
        window.SERVICES.vmtcore.applyBlender(241,193,171,0.25);
        window.SERVICES.vmtcore.applyBlush("blush_1", 217, 128, 117, 0.15,'blush_shimmer',0.0);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",230,211,182,0.4);
        window.SERVICES.vmtcore.applyLipstick(255, 88, 133, 0.7019607843137254, 1, 'lipGlitter_2',0,'lipShimmer_1',0.25);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_11", 154, 122, 94, 0.74,'eyeshadow_shimmer',0.05);
        window.SERVICES.vmtcore.applyEyelash("eyelash_5", 25, 25, 25, 0.4);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_10", 244, 244, 222, 0.5);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 168, 133, 105, 0.88);
      }

      if(idx == 6){
        window.SERVICES.vmtcore.applyFoundation(202, 149, 122, 0.22,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlush("blush_1", 222, 146, 122, 0.22,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyBronzer('bronzer_sunkiss',169,109,78,0.2);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_2",121,86,60,0.15);
        window.SERVICES.vmtcore.applyLipstick(200, 137, 116, 0.6, 2, 'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_6", 154, 116, 86, 1,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_6", 25, 25, 25, 0.44);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_3", 33, 35, 35, 0.33);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 106, 90, 77, 0.42);
      }

      if(idx == 7){
        window.SERVICES.vmtcore.applyBlender(232,119,204,0.25);
        window.SERVICES.vmtcore.applyBlush("blush_2", 180, 117, 103, 0.1,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyLipstick(165, 70, 40, 0.6588235294117647, 2, 'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_1", 81, 49, 32, 0.5,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_7", 25, 25, 25, 0.4);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_4", 77, 72, 66, 0.5490196078431373);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 136, 109, 88, 0.75);
      }

      if(idx == 8){
        window.SERVICES.vmtcore.applyFoundation(244, 208, 142, 0.1,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlender(167,126,89,0.5);
        window.SERVICES.vmtcore.applyBlush("blush_3", 169, 111, 75, 0.25,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyLipliner('lipliner_1',171,24,51,0.3);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",228,211,182,0.25);
        window.SERVICES.vmtcore.applyLipstick(171, 24, 51, 0.8, 1, 'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_4", 108, 74, 45, 1,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_8", 25, 25, 25, 0.55);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_5", 33, 35, 35, 0.4);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 114, 79, 65, 0.5);
      }

      if(idx == 9){
        window.SERVICES.vmtcore.applyFoundation(237, 202, 171, 0.1,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlender(208,153,239,0.15);
        window.SERVICES.vmtcore.applyBronzer('bronzer_contour',162,121,83,0.5019607843137255);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",231,211,188,0.3);
        window.SERVICES.vmtcore.applyLipstick(179, 35, 132, 0.8392156862745098, 0, 'lipGlitter_2',0,'lipShimmer_1',0.5);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_5", 235, 228, 232, 0.75,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_9", 25, 25, 25, 0.6);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_6", 33, 35, 35, 0.45);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder_3", 98, 75, 56, 0.7);
      }

      if(idx == 10){
        window.SERVICES.vmtcore.applyFoundation(213, 155, 128, 0.2235294117647059,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlush("blush_3", 213, 142, 114, 0.2,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyLipliner('lipliner_3',200,100,75,0.45);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",228,211,182,0.2);
        window.SERVICES.vmtcore.applyLipstick(180, 100, 100, 0.8784313725490196, 0, 'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_20", 107, 74, 58, 0.6,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_10", 25, 25, 25, 0.35);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_7", 33, 35, 35, 0.35);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder_5", 132, 111, 94, 0.55);
      }

      if(idx == 11){
        window.SERVICES.vmtcore.applyBlender(241,217,206,0.3);
        window.SERVICES.vmtcore.applyLipliner('lipliner_1',181,112,96,0.4392156862745098);
        window.SERVICES.vmtcore.applyLipstick(111, 36, 36, 0.7490196078431373, 1, 'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_10", 88, 65, 55, 0.5490196078431373,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_8", 33, 35, 35, 0.55);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 130, 100, 70, 0.8);
      }

      if(idx == 12){
        window.SERVICES.vmtcore.applyFoundation(206, 132, 98, 0.2,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlush("blush_3", 235, 130, 88, 0.3,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",220,200,188,0.6);
        window.SERVICES.vmtcore.applyLipstick(200, 10, 10, 0.9019607843137255, 1, 'lipGlitter_2',0,'lipShimmer_1',0.15);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_23", 155, 38, 38, 0.5,'eyeshadow_shimmer',0.2);
        window.SERVICES.vmtcore.applyEyelash("eyelash_10", 25, 25, 25, 0.4);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_9", 35, 35, 35, 0.55);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder_3", 115, 55, 35, 0.55);
      }

      if(idx == 13){
        window.SERVICES.vmtcore.applyFoundation(234, 195, 178, 0.1,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlush("blush_3", 222, 150, 120, 0.35,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyLipliner('lipliner_2',230,144,127,1);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",240,200,160,0.44);
        window.SERVICES.vmtcore.applyLipstick(135, 65, 85, 0.75, 1, 'lipGlitter_2',0,'lipShimmer_1',0.2);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_25", 90, 45, 20, 0.5,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_11", 25, 25, 25, 0.35);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder_5", 150, 125, 100, 0.88);
      }

      if(idx == 14){
        window.SERVICES.vmtcore.applyFoundation(200, 150, 120, 0.2,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlush("blush_2", 200, 120, 100, 0.35,'blush_shimmer',0);
        window.SERVICES.vmtcore.applyBronzer('bronzer_fullface',50,40,30,0.4);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",225,210,180,0.2196078431372549);
        window.SERVICES.vmtcore.applyLipstick(111, 60, 50, 0.7, 1, 'lipGlitter_2',0,'lipShimmer_1',0);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_15", 200, 100, 88, 0.5,'eyeshadow_shimmer',0);
        window.SERVICES.vmtcore.applyEyelash("eyelash_12", 25, 25, 25, 0.45);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_10", 35, 35, 35, 0.45098039215686275);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder_3", 124, 100, 77, 0.66);
      }

      if(idx == 15){
        window.SERVICES.vmtcore.applyFoundation(200, 160, 122, 0.2235294117647059,'foundation_fullface');
        window.SERVICES.vmtcore.applyBlush("blush_3", 222, 150, 120, 0.5,'blush_shimmer',0.25);
        window.SERVICES.vmtcore.applyHighlighter('highlighter_1',217,180,117,0.15,'highlighter_shimmer',0.25);
        window.SERVICES.vmtcore.applyBronzer('bronzer_fullface',94,78,63,0.55);
        window.SERVICES.vmtcore.applyContour("bronzer_fullface_contour_3",228,211,182,0.3);
        window.SERVICES.vmtcore.applyLipstick(65, 5, 5, 0.8784313725490196, 1, 'lipGlitter_2',0,'lipShimmer_1',0.15);
        window.SERVICES.vmtcore.applyEyeshadow("eyeshadow_15", 210, 150, 115, 0.6,'eyeshadow_shimmer',0.15);
        window.SERVICES.vmtcore.applyEyelash("eyelash_13", 25, 25, 25, 0.4);
        window.SERVICES.vmtcore.applyEyeliner("eyeliner_11", 33, 35, 35, 0.55);
        window.SERVICES.vmtcore.applyEyebrow("eyebrow_powder", 122, 90, 70, 0.7);
      }

    }

    handleMoveLeft(){
      if(this.state.scrollVal + 200 >= 10){
        this.setState({scrollVal:10});
      }else if(this.state.scrollVal + 200 < 10){
        this.setState({scrollVal:this.state.scrollVal + 200});
      }
    }
    handleMoveRight(){
      let div = document.getElementById('looks-carousel');
      let max_left = -1 * (div.offsetWidth - div.offsetParent.offsetWidth + 100);
      if(this.state.scrollVal - 200  <= max_left){
        this.setState({scrollVal: max_left});
      }else if(this.state.scrollVal - 200 > max_left){
        this.setState({scrollVal:this.state.scrollVal - 200});
      }
    }
    
    resetCarousel(){
      this.setState(initialState);
    }

    componentWillUnmount(){
      this.setState(initialState);
    }

    componentDidMount(){
      var self = this;
      document.addEventListener('reset_request_3', self.resetCarousel);
    }

    render(){

        if(AppStore.looksResetStatus && AppStore.productsResetStatus){
          this.setState(initialState);
          AppStore.setLooksResetStatus(false);
          AppStore.setProductsResetStatus(false);
        }
        
        return(
          <CustomAccordion fluid styled>
            <CustomAccItemTitle active={true}>
              <AccordionItem itemTitle="Looks"/>
            </CustomAccItemTitle>
            <Accordion.Content active={true}>
                
            <CustomSectionContainer>
              <CustomButton icon onClick={this.handleMoveLeft}><Icon name='angle left' /></CustomButton>
              <CustomSegmentContainer horizontal>
                <CustomSegmentContainerMoving 
                  horizontal 
                  id='looks-carousel'
                  style={ { left: `${this.state.scrollVal}px` } }>
                  <CustomHorizontalSegment>
                      <CustomCarouselItemContainer className={`${(this.state.selected===-1 ) ? 'checkered carousel-show-border':'checkered carousel-no-show-border'}`} onClick={(e)=>this.handleMakeup(e,-1)} style={{background :`url(${CheckerPattern}) repeat`}}></CustomCarouselItemContainer>
                  </CustomHorizontalSegment>
                  {
                    [...Array(16).keys()].splice(1).map((item, index)=>{
                      return(
                        <CustomHorizontalSegment>
                          <CustomCarouselItemContainer className={`${(this.state.selected===item ) ? 'carousel-show-border':'carousel-no-show-border'}`} onClick={(e)=>this.handleMakeup(e,item)} style={{backgroundImage:`url(/Looks/${item}.jpg)`}}>
                          </CustomCarouselItemContainer>
                        </CustomHorizontalSegment>
                      );
                    })
                  }
                </CustomSegmentContainerMoving>
              </CustomSegmentContainer>
              <CustomButton icon onClick={this.handleMoveRight}><Icon name='angle right' /></CustomButton>
            </CustomSectionContainer>

            </Accordion.Content>
          </CustomAccordion>
        );
    }
}
LooksMenu = observer(LooksMenu);
export default LooksMenu;
