import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Segment, Button, Icon, Label, Card, Grid, Container } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import {NudestQuestionnaire} from '.';
import NudestStore from '../stores/NudestStore';

const NudestFoundationMakeup = [
  {"idx": 0, "type": "FOUNDATION", "sku_id": "0N1", "sku_name": "0N1", "r": 193, "g": 163, "b": 144, "a": 0.5},
  {"idx": 1, "type": "FOUNDATION", "sku_id": "1N0", "sku_name": "1N0", "r": 193, "g": 161, "b": 139, "a": 0.5},
  {"idx": 2, "type": "FOUNDATION", "sku_id": "1W0", "sku_name": "1W0", "r": 192, "g": 161, "b": 135, "a": 0.5},
  {"idx": 3, "type": "FOUNDATION", "sku_id": "1W1", "sku_name": "1W1", "r": 190, "g": 159, "b": 134, "a": 0.5},
  {"idx": 4, "type": "FOUNDATION", "sku_id": "1N1", "sku_name": "1N1", "r": 191, "g": 158, "b": 136, "a": 0.5},
  {"idx": 5, "type": "FOUNDATION", "sku_id": "1C0", "sku_name": "1C0", "r": 194, "g": 157, "b": 139, "a": 0.5},
  {"idx": 6, "type": "FOUNDATION", "sku_id": "2C0", "sku_name": "2C0", "r": 190, "g": 156, "b": 135, "a": 0.5},
  {"idx": 7, "type": "FOUNDATION", "sku_id": "1C1", "sku_name": "1C1", "r": 189, "g": 152, "b": 135, "a": 0.5},
  {"idx": 8, "type": "FOUNDATION", "sku_id": "2W0", "sku_name": "2W0", "r": 180, "g": 148, "b": 124, "a": 0.5},
  {"idx": 9, "type": "FOUNDATION", "sku_id": "1N2", "sku_name": "1N2", "r": 183, "g": 147, "b": 123, "a": 0.5},
  {"idx": 10, "type": "FOUNDATION", "sku_id": "1W2", "sku_name": "1W2", "r": 181, "g": 147, "b": 123, "a": 0.5},
  {"idx": 11, "type": "FOUNDATION", "sku_id": "2W1", "sku_name": "2W1", "r": 179, "g": 142, "b": 117, "a": 0.5},
  {"idx": 12, "type": "FOUNDATION", "sku_id": "2W2", "sku_name": "2W2", "r": 176, "g": 143, "b": 114, "a": 0.5},
  {"idx": 13, "type": "FOUNDATION", "sku_id": "2C4", "sku_name": "2C4", "r": 184, "g": 138, "b": 122, "a": 0.5},
  {"idx": 14, "type": "FOUNDATION", "sku_id": "2N1", "sku_name": "2N1", "r": 179, "g": 140, "b": 116, "a": 0.5},
  {"idx": 15, "type": "FOUNDATION", "sku_id": "2N2", "sku_name": "2N2", "r": 175, "g": 142, "b": 116, "a": 0.5},
  {"idx": 16, "type": "FOUNDATION", "sku_id": "2C1", "sku_name": "2C1", "r": 179, "g": 139, "b": 119, "a": 0.5},
  {"idx": 17, "type": "FOUNDATION", "sku_id": "3C1", "sku_name": "3C1", "r": 176, "g": 135, "b": 111, "a": 0.5},
  {"idx": 18, "type": "FOUNDATION", "sku_id": "3W1", "sku_name": "3W1", "r": 172, "g": 135, "b": 104, "a": 0.5},
  {"idx": 19, "type": "FOUNDATION", "sku_id": "2C3", "sku_name": "2C3", "r": 173, "g": 131, "b": 110, "a": 0.5},
  {"idx": 20, "type": "FOUNDATION", "sku_id": "4W1", "sku_name": "4W1", "r": 163, "g": 128, "b": 93, "a": 0.5},
  {"idx": 21, "type": "FOUNDATION", "sku_id": "3C2", "sku_name": "3C2", "r": 172, "g": 123, "b": 103, "a": 0.5},
  {"idx": 22, "type": "FOUNDATION", "sku_id": "4C1", "sku_name": "4C1", "r": 172, "g": 123, "b": 105, "a": 0.5},
  {"idx": 23, "type": "FOUNDATION", "sku_id": "3W2", "sku_name": "3W2", "r": 166, "g": 126, "b": 93, "a": 0.5},
  {"idx": 24, "type": "FOUNDATION", "sku_id": "3N1", "sku_name": "3N1", "r": 171, "g": 123, "b": 94, "a": 0.5},
  {"idx": 25, "type": "FOUNDATION", "sku_id": "3N2", "sku_name": "3N2", "r": 170, "g": 124, "b": 92, "a": 0.5},
  {"idx": 26, "type": "FOUNDATION", "sku_id": "3W1.5", "sku_name": "3W1.5", "r":166, "g": 124, "b":95 , "a": 0.5},
  {"idx": 27, "type": "FOUNDATION", "sku_id": "3C3", "sku_name": "3C3", "r": 164, "g": 115, "b": 86, "a": 0.5},
  {"idx": 28, "type": "FOUNDATION", "sku_id": "4N2", "sku_name": "4N2", "r": 157, "g": 117, "b": 84, "a": 0.5},
  {"idx": 29, "type": "FOUNDATION", "sku_id": "4W3", "sku_name": "4W3", "r": 158, "g": 116, "b": 84, "a": 0.5},
  {"idx": 30, "type": "FOUNDATION", "sku_id": "4W4", "sku_name": "4W4", "r": 154, "g": 117, "b": 79, "a": 0.5},
  {"idx": 31, "type": "FOUNDATION", "sku_id": "4N1", "sku_name": "4N1", "r": 160, "g": 114, "b": 82, "a": 0.5},
  {"idx": 32, "type": "FOUNDATION", "sku_id": "5W1", "sku_name": "5W1", "r": 149, "g": 107, "b": 74, "a": 0.5},
  {"idx": 33, "type": "FOUNDATION", "sku_id": "4N3", "sku_name": "4N3", "r": 154, "g": 105, "b": 72, "a": 0.5},
  {"idx": 34, "type": "FOUNDATION", "sku_id": "4C3", "sku_name": "4C3", "r": 155, "g": 103, "b": 74, "a": 0.5},
  {"idx": 35, "type": "FOUNDATION", "sku_id": "4C2", "sku_name": "4C2", "r": 155, "g": 102, "b": 76, "a": 0.5},
  {"idx": 36, "type": "FOUNDATION", "sku_id": "4W2", "sku_name": "4W2", "r": 152, "g": 103, "b": 68, "a": 0.5},
  {"idx": 37, "type": "FOUNDATION", "sku_id": "5N1.5", "sku_name": "5N1.5", "r":140, "g": 95, "b": 57, "a": 0.5},
  {"idx": 38, "type": "FOUNDATION", "sku_id": "5C2", "sku_name": "5C2", "r": 142, "g": 91, "b": 64, "a": 0.5},
  {"idx": 39, "type": "FOUNDATION", "sku_id": "5W1.5", "sku_name": "5W1.5", "r":132, "g":96 , "b":57 , "a": 0.5},
  {"idx": 40, "type": "FOUNDATION", "sku_id": "5W2", "sku_name": "5W2", "r": 143, "g": 90, "b": 58, "a": 0.5},
  {"idx": 41, "type": "FOUNDATION", "sku_id": "5N1", "sku_name": "5N1", "r": 142, "g": 91, "b": 56, "a": 0.5},
  {"idx": 42, "type": "FOUNDATION", "sku_id": "6W1", "sku_name": "6W1", "r": 124, "g": 81, "b": 43, "a": 0.5},
  {"idx": 43, "type": "FOUNDATION", "sku_id": "5C1", "sku_name": "5C1", "r": 128, "g": 79, "b": 47, "a": 0.5},
  {"idx": 44, "type": "FOUNDATION", "sku_id": "6N1", "sku_name": "6N1", "r": 124, "g": 78, "b": 39, "a": 0.5},
  {"idx": 45, "type": "FOUNDATION", "sku_id": "6C1", "sku_name": "6C1", "r": 125, "g": 76, "b": 46, "a": 0.5},
  {"idx": 46, "type": "FOUNDATION", "sku_id": "5N2", "sku_name": "5N2", "r": 124, "g": 76, "b": 41, "a": 0.5},
  {"idx": 47, "type": "FOUNDATION", "sku_id": "6W2", "sku_name": "6W2", "r": 106, "g": 67, "b": 26, "a": 0.5},
  {"idx": 48, "type": "FOUNDATION", "sku_id": "7N1", "sku_name": "7N1", "r": 106, "g": 62, "b": 26, "a": 0.5},
  {"idx": 49, "type": "FOUNDATION", "sku_id": "6N2", "sku_name": "6N2", "r": 105, "g": 62, "b": 20, "a": 0.5},
  {"idx": 50, "type": "FOUNDATION", "sku_id": "6C2", "sku_name": "6C2", "r": 106, "g": 59, "b": 28, "a": 0.5},
  {"idx": 51, "type": "FOUNDATION", "sku_id": "7W1", "sku_name": "7W1", "r": 83, "g": 46, "b": 1, "a": 0.5},
  {"idx": 52, "type": "FOUNDATION", "sku_id": "8N1", "sku_name": "8N1", "r": 84, "g": 45, "b": 2, "a": 0.5},
  {"idx": 53, "type": "FOUNDATION", "sku_id": "7C1", "sku_name": "7C1", "r": 81, "g": 45, "b": 15, "a": 0.5},
  {"idx": 54, "type": "FOUNDATION", "sku_id": "7C2", "sku_name": "7C2", "r": 81, "g": 43, "b": 11, "a": 0.5},
  {"idx": 55, "type": "FOUNDATION", "sku_id": "8C1", "sku_name": "8C1", "r": 59, "g": 23, "b": 0, "a": 0.5},
]

const NudestFoundationColorMap = [
  {fake_clr:[251,220,189,1]},
  {fake_clr:[230,187,152,1]},
  {fake_clr:[239,201,154,1]},
  {fake_clr:[255,203,166,1]},
  {fake_clr:[255,200,163,1]},
  {fake_clr:[216,187,163,1]},
  {fake_clr:[222,163,101,1]},
  {fake_clr:[255,214,186,1]},
  {fake_clr:[212,160,102,1]},
  {fake_clr:[250,199,167,1]},
  {fake_clr:[229,181,147,1]},
  {fake_clr:[242,191,145,1]},
  {fake_clr:[237,190,152,1]},
  {fake_clr:[194,149,112,1]},
  {fake_clr:[242,187,150,1]},
  {fake_clr:[208,162,110,1]},
  {fake_clr:[216,164,109,1]},
  {fake_clr:[231,183,156,1]},
  {fake_clr:[207,144,93,1]},
  {fake_clr:[221,180,145,1]},
  {fake_clr:[194,116,64,1]},
  {fake_clr:[235,173,129,1]},
  {fake_clr:[202,151,122,1]},
  {fake_clr:[214,144,96,1]},
  {fake_clr:[229,173,138,1]},
  {fake_clr:[213,154,113,1]},
  {fake_clr:[196,143,86,1]},
  {fake_clr:[188,131,68,1]},
  {fake_clr:[173,114,71,1]},
  {fake_clr:[171,126,73,1]},
  {fake_clr:[204,148,70,1]},
  {fake_clr:[215,152,109,1]},
  {fake_clr:[181,110,56,1]},
  {fake_clr:[198,143,79,1]},
  {fake_clr:[190,128,88,1]},
  {fake_clr:[171,111,55,1]},
  {fake_clr:[192,151,107,1]},
  {fake_clr:[168,104,52,1]},
  {fake_clr:[200,134,68,1]},
  {fake_clr:[187,128,70,1]},
  {fake_clr:[168,95,42,1]},
  {fake_clr:[218,146,107,1]},
  {fake_clr:[170,101,51,1]},
  {fake_clr:[153,86,44,1]},
  {fake_clr:[143,92,53,1]},
  {fake_clr:[143,77,43,1]},
  {fake_clr:[163,88,42,1]},
  {fake_clr:[146,91,45,1]},
  {fake_clr:[114,71,39,1]},
  {fake_clr:[137,92,38,1]},
  {fake_clr:[134,79,38,1]},
  {fake_clr:[126,81,45,1]},
  {fake_clr:[94,54,23,1]},
  {fake_clr:[119,66,30,1]},
  {fake_clr:[128,76,20,1]},
  {fake_clr:[105,61,37,1]}
]

const CustomCarouselItemContainer = styled('div')`
  width: 30px !important;
  height: 30px !important;
  margin: auto !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  &:hover{
    border: 2px solid #303030 !important;
  }
`

const NoPaddingSegment = styled(Segment)`
    border: unset !important;
    box-shadow: unset !important;
    margin: 20px 10% !important;
    padding: 0px !important;
`
const CustomButton = styled(Button)`
  font-family: robo-reg !important;
  font-size: 1.3rem !important;
  transition: background-color 0.5s ease !important;
`
const CustomLabel = styled('div')`
  text-align: center;
  margin: 10px;
  font-size: 1.3rem;
  font-family: robo-bold;
  color: #767676; 
`

const NoPaddingSegment2 = styled(Segment)`
    border: unset !important;
    box-shadow: unset !important;
`
const NoPaddingSegmentGroup = styled(Segment)`
  border: unset !important;
  box-shadow: unset !important;
  padding: 0px !important;
`

const NoPaddingSegment3 = styled('div')`
  text-align: center !important;
`

const SuccessTextIndicator = styled('div')`
  font-size: 1.2rem !important;
  color: #5fca78 !important;
  font-style: italic !important;
`

const FailureTextIndicator = styled('div')`
  font-size: 1.2rem !important;  
  color: #c34b4b !important;
  font-style: italic !important;
`

const CheckerPattern = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPklEQVQoU2OcOHGiMQMRgJEYhfn5+WcJKgQpAlmIVyFMEV6FyIpwKkRXhFUhNkUYCnEpQlGITxFcISFFIIUAEg0l8ZG1WX0AAAAASUVORK5CYII="


const Recommendation = (props) => {
  var item = NudestFoundationMakeup[props.index];
  var item_clr_map = NudestFoundationColorMap[props.index];
  var index = props.index;
  return (
    <CustomCarouselItemContainer 
    className={`${(props.selected===props.index ) ? 'carousel-show-border':'carousel-no-show-border'}`} 
    onClick={(e)=>props.handleItemClick(e,index,item)} style={{backgroundColor:`rgba(${item_clr_map.fake_clr[0]}, ${item_clr_map.fake_clr[1]}, ${item_clr_map.fake_clr[2]}, ${item_clr_map.fake_clr[3]*100})`}}
    onMouseEnter={(e)=>{
        props.mouseHandle({sku_name:item.sku_name.toLowerCase()});
    }}
    onMouseLeave={(e)=>props.mouseHandle(null)}>
  </CustomCarouselItemContainer>
  )
}

const initialState = {
  selected : -1,
  intoNudestRejected : false,
  showNudestQuestionnaire: false,

  rec_seletedID: 0,
  api_result : null,
}
export class NudestFoundationCarousel extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.handleMakeup = this.handleMakeup.bind(this);
      this.handleNudestQuestionnaireLaunch = this.handleNudestQuestionnaireLaunch.bind(this);
      this.closeNudestQuestionnaire = this.closeNudestQuestionnaire.bind(this);
      this.dismissResults = this.dismissResults.bind(this);
      this.resetCarousel = this.resetCarousel.bind(this);
    }

    handleNudestQuestionnaireLaunch(){
      if(AppStore.currentPage === AppStore.PagesEnum.LIVE){
        this.setState({intoNudestRejected:true});
        var self = this;
        setTimeout(()=>{
          self.setState({intoNudestRejected:false});
        },2000);
      }
      else{
        this.setState({showNudestQuestionnaire:true});
      }
    }

    dismissResults(){
      this.setState({api_result:null});
      NudestStore.cleanSlate();
    }

    closeNudestQuestionnaire(){
      this.setState({showNudestQuestionnaire:false});
      if(NudestStore.results != ""){
        var resObj = JSON.parse(NudestStore.results);
        var outObj = {};

        if(resObj.skinToneKeyInArray !== undefined && resObj.skinToneKeyInArray == -1){
          return;
        }
        else if(resObj.skinTone != undefined){
          outObj = {
            winter : resObj.skinTone,
            spring : resObj.skinTone,
            summer : resObj.skinTone,
            autumn : resObj.skinTone,
            undertone: "neutral",
          }

          if(resObj.seasonalSkinTone != undefined){
            var self = this;
            resObj.seasons.forEach((e)=>{
              if(e == "winter" && resObj.winterSkinTone != undefined) outObj.winter = resObj.winterSkinTone;
              if(e == "spring" && resObj.springSkinTone != undefined) outObj.spring = resObj.springSkinTone;
              if(e == "summer" && resObj.summerSkinTone != undefined) outObj.summer = resObj.summerSkinTone;
              if(e == "autumn" && resObj.autumnSkinTone != undefined) outObj.autumn = resObj.autumnSkinTone;
            });
          }

          if (resObj.myUnderTone != undefined) {
            outObj.undertone = resObj.myUnderTone
          }

          this.setState({api_result:outObj});

        }


      }
    }

    handleMakeup(e,i,item){

      /** rest makeup element */
      let remove = false;
      if( i == -1){
        remove = true;
      }

      this.setState({selected:i});

      if(item.type === "FOUNDATION"){
        window.SERVICES.vmtcore.removeFoundation();
        if(!remove){
          window.SERVICES.vmtcore.applyFoundation(item.r, item.g, item.b, item.a);
        }
      }
    }

    componentDidMount(){
      this.dismissResults();
      var self = this;
      document.addEventListener('reset_request_2', self.resetCarousel);
    }
    
    resetCarousel(){
      this.setState(initialState);
    }

    componentWillUnmount(){
      this.setState(initialState);
    }

    render(){
        return(
          <Container>
            <NoPaddingSegment2>
              { (this.state.showNudestQuestionnaire) &&
                <NudestQuestionnaire closeHandle={this.closeNudestQuestionnaire}/>
              }
              {
                (NudestStore.results !== "") &&
                <Card className="nudest-result-font" fluid>
                  <Card.Content>
                    <Button basic floated='right' color='red' labelPosition='left' icon onClick={this.dismissResults}><Icon name='close' />Dismiss</Button>
                    <Card.Header textAlign='left'>Your Results</Card.Header>
                    <Card.Meta textAlign='left'>
                      {
                        (()=>{
                          if(this.state.api_result != null){
                            return(<SuccessTextIndicator>Success</SuccessTextIndicator>);
                          }
                          else{
                            return(<FailureTextIndicator>Failure</FailureTextIndicator>);
                          }
                        })()
                      }
                    </Card.Meta>
                    {
                      (()=>{
                        if(this.state.api_result != null){
                          return(<Card.Description>based on your <strong>{this.state.api_result.undertone}</strong> undertones and how your skin reacts to sun exposure, we recommend :</Card.Description>);
                        }
                        else{
                          return(<Card.Description>Sorry! We could not find results for you, Please try again Later.</Card.Description>);
                        }
                      })()
                    }
                  </Card.Content>
                  {
                    (this.state.api_result != null) && 
                    <Card.Content extra>
                      <Segment.Group horizontal>
                        <Segment>
                          <NoPaddingSegment3>Dec to Feb</NoPaddingSegment3>
                          <NoPaddingSegment3>
                            <Recommendation 
                            handleItemClick={this.handleMakeup} 
                            index={this.state.api_result.winter} 
                            selected={this.state.selected}
                            mouseHandle={this.props.setCarouselItem}/>
                          </NoPaddingSegment3>
                        </Segment>
                        <Segment>
                          <NoPaddingSegment3>Mar to May</NoPaddingSegment3>
                          <NoPaddingSegment3>
                            <Recommendation 
                            handleItemClick={this.handleMakeup} 
                            index={this.state.api_result.spring} 
                            selected={this.state.selected}
                            mouseHandle={this.props.setCarouselItem}/>
                          </NoPaddingSegment3>
                        </Segment>
                        <Segment>
                          <NoPaddingSegment3>June to Aug</NoPaddingSegment3>
                          <NoPaddingSegment3>
                            <Recommendation 
                            handleItemClick={this.handleMakeup} 
                            index={this.state.api_result.summer} 
                            selected={this.state.selected}
                            mouseHandle={this.props.setCarouselItem}/>
                          </NoPaddingSegment3>
                        </Segment>
                        <Segment>
                          <NoPaddingSegment3>Sep to Nov</NoPaddingSegment3>
                          <NoPaddingSegment3>
                            <Recommendation 
                            handleItemClick={this.handleMakeup} 
                            index={this.state.api_result.autumn} 
                            selected={this.state.selected}
                            mouseHandle={this.props.setCarouselItem}/>
                          </NoPaddingSegment3>
                        </Segment>
                      </Segment.Group>
                    </Card.Content>
                  }
                </Card>
              }
            </NoPaddingSegment2>
            <Grid columns={8} className="nudest-foundation-carousel">
                <Grid.Column>
                  <CustomCarouselItemContainer className={`${(this.state.selected===-1 ) ? 'carousel-show-border':'carousel-no-show-border'}`} onClick={(e)=>this.handleMakeup(e,-1, NudestFoundationMakeup[0])} style={{background :`url(${CheckerPattern}) repeat`}}></CustomCarouselItemContainer>
                </Grid.Column>
                {
                  NudestFoundationMakeup.map((item, index)=>{
                    let self = this;
                    return(
                      <Grid.Column>
                        <CustomCarouselItemContainer 
                          className={`${(self.state.selected===index ) ? 'carousel-show-border':'carousel-no-show-border'}`} 
                          onClick={(e)=>self.handleMakeup(e,index,item)} style={{backgroundColor:`rgba(${NudestFoundationColorMap[item.idx].fake_clr[0]}, ${NudestFoundationColorMap[item.idx].fake_clr[1]}, ${NudestFoundationColorMap[item.idx].fake_clr[2]}, ${NudestFoundationColorMap[item.idx].fake_clr[3]*100})`}}
                          onMouseEnter={(e)=>{
                              self.props.setCarouselItem({sku_name:item.sku_name.toLowerCase()});
                          }}
                          onMouseLeave={(e)=>self.props.setCarouselItem(null)}>
                        </CustomCarouselItemContainer>
                      </Grid.Column>
                    );
                  })
                }
            </Grid>
            <NoPaddingSegment>
              <CustomLabel>NOT SURE WHICH FOUNDATION SHADE IS BEST FOR YOU ?</CustomLabel>
              <CustomButton fluid color={`${(this.state.intoNudestRejected)?"red":""}`} onClick={this.handleNudestQuestionnaireLaunch}>{(this.state.intoNudestRejected)?"You're in live mode, take a picture by clicking the camera icon and retry":"Match Your Skin Tone"}</CustomButton>
            </NoPaddingSegment>    
          </Container>
        );
    }
}
NudestFoundationCarousel = observer(NudestFoundationCarousel);
export default NudestFoundationCarousel;
