import * as React from 'react';
import styled from '@emotion/styled';
import {observer} from 'mobx-react';
import { Segment, Button, Icon, Image, Popup } from 'semantic-ui-react'
import {ShoppingItem} from '.';
import AppStore from '../stores/AppStore';
import MakeupStore from '../stores/MakeupStore';

const CustomSectionContainer = styled(Segment)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  display: flex !important;
  position: relative !important;
  background-color: var(--accordion-carousel-bg) !important;
`

const CustomSegmentContainer = styled(Segment.Group)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  width: 95% !important;
  margin: 0px 2.5% !important;
  background-color: var(--accordion-carousel-fg) !important;
  height: 60px !important;
  overflow-x: hidden;
`
const CustomSegmentContainerMoving = styled(Segment.Group)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  height: inherit !important;
  position: absolute !important;
  top: 0px;
  left : 0px;
  -webkit-transition: left 0.8s; /* Safari */
  transition: left 0.8s;
`
const CustomHorizontalSegment = styled(Segment)`
  padding: 0px !important;
  border: unset !important;
  box-shadow: unset !important;
  margin: 0px 15px !important;
  display: flex !important;
`
const CustomButton = styled(Button)`
  color: var(--accordion-carousel-move-btn-txt-clr) !important;
  background: var(--accordion-carousel-move-btn-bg) !important;
  &:hover{
    color: var(--accordion-carousel-move-btn-txt-clr-hover) !important;
    background: var(--accordion-carousel-move-btn-bg-hover) !important;
  }
`



const CustomCarouselItemContainer = styled('div')`
  width: 30px !important;
  height: 30px !important;
  margin: auto !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  &:hover{
    border: 2px solid var(--accordion-carousel-item-border-hover) !important;
  }
`

const AbsoluteHiddenCard = styled('div')`
  position: absolute !important;
  z-index: 100;
  top: 70px;
  left: 0px;
`

const CheckerPattern = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAALElEQVQYV2P8////fwYkwMjIKM2ILAgSAMnDBWECcEFkAbAgAwODFLKZIDYAiyYQiKtJnA0AAAAASUVORK5CYII="

const initialState = {
  scrollVal: 10,
  selected : -1,
  infoObj : null,
  isTouch: false,
}
export class Carousel extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState;
      this.groupedData = {}
      this.handleMoveLeft = this.handleMoveLeft.bind(this);
      this.handleMoveRight = this.handleMoveRight.bind(this);
      this.handleMakeup = this.handleMakeup.bind(this);
      this.resetCarousel = this.resetCarousel.bind(this);
      this.generateMultiColor = this.generateMultiColor.bind(this);
      this.handleMultiMakeup = this.handleMultiMakeup.bind(this);
      this.identifyItem = this.identifyItem.bind(this);
    }

    identifyItem(item){
      var op = null
      if(item !== null){
        var sku_id = item.sku_id;
        var category = item.type;
        op = MakeupStore.getShoppingCartMatchingProductSingle(sku_id, category);
      }
      this.setState({infoObj:op})
    }

    handleMultiMakeup(e, i, items){
      this.setState({selected:i});
      MakeupStore.setShoppingCartSelection(items[0].type, items[0].sku_id);
      if(items[0].type === "FOUNDATION"){
        window.SERVICES.vmtcore.removeFoundation();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyFoundation(item.r, item.g, item.b, item.a, item.mask,item.finish_type,item.finish_intensity,item.smoothing_coefficent,item.shininess,item.blending_ratio);
        })
      }
      if(items[0].type === "BLUSH"){
        window.SERVICES.vmtcore.removeBlush();
        items.forEach((item)=>{
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyBlush(item.mask, item.r, item.g, item.b, item.a, 
            shimmer_mask, shimmer_intensity, item.shimmer_r, item.shimmer_g, item.shimmer_b);
        })
      }
      if(items[0].type === "LIPSTICK"){
        window.SERVICES.vmtcore.removeLipstick();
        items.forEach((item)=>{
          let glitter_mask = item["glitter_mask"] || null;
          let glitter_intensity = item["glitter_intensity"] || null;
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyLipstick(item.r, item.g, item.b, item.a, 
            item.finish_intensity, item.finish_type, 
            glitter_mask, glitter_intensity, 
            shimmer_mask, shimmer_intensity);
        })
      }
      if(items[0].type === "LIPLINER"){
        window.SERVICES.vmtcore.removeLipliner();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyLipliner(item.mask, item.r, item.g, item.b, item.a);
        })
      }
      if(items[0].type === "HIGHLIGHTER"){
        window.SERVICES.vmtcore.removeHighlighter();
        items.forEach((item)=>{
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyHighlighter(item.mask, item.r, item.g, item.b, item.a, 
            shimmer_mask, shimmer_intensity);
        })
      }
      if(items[0].type === "CONTOUR"){
        window.SERVICES.vmtcore.removeContour();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyContour(item.mask, item.r, item.g, item.b, item.a);
        })
      }
      if(items[0].type === "BRONZER"){
        window.SERVICES.vmtcore.removeBronzer();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyBronzer(item.mask, item.r, item.g, item.b, item.a);
        })
      }
      if(items[0].type === "EYESHADOW"){
        window.SERVICES.vmtcore.removeEyeshadow();
        items.forEach((item)=>{
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyEyeshadow(item.mask, item.r, item.g, item.b, item.a, 
            shimmer_mask, shimmer_intensity, item.shimmer_r, item.shimmer_g, item.shimmer_b, item.shimmer_a);
        })
      }
      if(items[0].type === "EYELASH"){
        window.SERVICES.vmtcore.removeEyelash();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyEyelash(item.mask, item.r, item.g, item.b, item.a);
        })
      }
      if(items[0].type === "EYELINER"){
        window.SERVICES.vmtcore.removeEyeliner();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyEyeliner(item.mask, item.r, item.g, item.b, item.a);
        })
      }
      if(items[0].type === "EYEBROW"){
        window.SERVICES.vmtcore.removeEyebrow();
        items.forEach((item)=>{
          window.SERVICES.vmtcore.applyEyebrow(item.mask, item.r, item.g, item.b, item.a);
        })
      }
    }

    handleMakeup(e,i,item){

      /** rest makeup element */
      let remove = false;
      if( i === -1){
        remove = true;
      }

      this.setState({selected:i});
      if(!remove) MakeupStore.setShoppingCartSelection(item.type, item.sku_id);
      if(item.type === "FOUNDATION"){
        window.SERVICES.vmtcore.removeFoundation();
        window.SERVICES.vmtcore.removeFoundation_2();
        if(!remove){
          window.SERVICES.vmtcore.applyFoundation(item.r, item.g, item.b, item.a, item.mask,item.finish_type,item.finish_intensity,item.smoothing_coefficent,item.shininess,item.blending_ratio);
        }
      }
      if(item.type === "FOUNDATION 2"){
        window.SERVICES.vmtcore.removeFoundation();
        window.SERVICES.vmtcore.removeFoundation_2();
        if(!remove){
          window.SERVICES.vmtcore.applyFoundation_2(item.r, item.g, item.b, item.a, item.mask,item.finish_type,item.finish_intensity,item.smoothing_coefficent,item.shininess,item.blending_ratio);
        }
      }
      if(item.type === "BLUSH"){
        window.SERVICES.vmtcore.removeBlush();
        if(!remove){
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyBlush(item.mask, item.r, item.g, item.b, item.a, 
            shimmer_mask, shimmer_intensity, item.shimmer_r, item.shimmer_g, item.shimmer_b);
        }
      }
      if(item.type === "LIPSTICK"){
        window.SERVICES.vmtcore.removeLipstick();
        if(!remove){
          let glitter_mask = item["glitter_mask"] || null;
          let glitter_intensity = item["glitter_intensity"] || null;
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyLipstick(item.r, item.g, item.b, item.a, 
            item.finish_intensity, item.finish_type, 
            glitter_mask, glitter_intensity, 
            shimmer_mask, shimmer_intensity);
        }
      }
      if(item.type === "LIPLINER"){
        window.SERVICES.vmtcore.removeLipliner();
        if(!remove){
          window.SERVICES.vmtcore.applyLipliner(item.mask, item.r, item.g, item.b, item.a);
        }
      }
      if(item.type === "CONTOUR"){
        window.SERVICES.vmtcore.removeContour();
        if(!remove){
          window.SERVICES.vmtcore.applyContour(item.mask, item.r, item.g, item.b, item.a);
        }
      }
      if(item.type === "BRONZER"){
        window.SERVICES.vmtcore.removeBronzer();
        if(!remove){
          window.SERVICES.vmtcore.applyBronzer(item.mask, item.r, item.g, item.b, item.a);
        }
      }
      if(item.type === "HIGHLIGHTER"){
        window.SERVICES.vmtcore.removeHighlighter();
        if(!remove){
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyHighlighter(item.mask, item.r, item.g, item.b, item.a, 
            shimmer_mask, shimmer_intensity);
        }
      }
      if(item.type === "EYESHADOW"){
        window.SERVICES.vmtcore.removeEyeshadow();
        if(!remove){
          let shimmer_mask = item["shimmer_mask"] || null;
          let shimmer_intensity = item["shimmer_intensity"] || null;
          window.SERVICES.vmtcore.applyEyeshadow(item.mask, item.r, item.g, item.b, item.a, 
            shimmer_mask, shimmer_intensity, item.shimmer_r, item.shimmer_g, item.shimmer_b, item.shimmer_a);
        }
      }
      if(item.type === "EYELASH"){
        window.SERVICES.vmtcore.removeEyelash();
        if(!remove){
          window.SERVICES.vmtcore.applyEyelash(item.mask, item.r, item.g, item.b, item.a);
        }
      }
      if(item.type === "EYELINER"){
        window.SERVICES.vmtcore.removeEyeliner();
        if(!remove){
          window.SERVICES.vmtcore.applyEyeliner(item.mask, item.r, item.g, item.b, item.a);
        }
      }
      if(item.type === "EYEBROW"){
        window.SERVICES.vmtcore.removeEyebrow();
        if(!remove){
          window.SERVICES.vmtcore.applyEyebrow(item.mask, item.r, item.g, item.b, item.a);
        }
      }

    }

    handleMoveLeft(){
      if(this.state.scrollVal + 200 >= 10){
        this.setState({scrollVal:10});
      }else if(this.state.scrollVal + 200 < 10){
        this.setState({scrollVal:this.state.scrollVal + 200});
      }
    }

    handleMoveRight(){
      let div = document.getElementById(this.props.data[0].type.toLowerCase()+'-carousel');
      let max_left = -1 * (div.offsetWidth - div.offsetParent.offsetWidth + 100);
      if(this.state.scrollVal - 200  <= max_left){
        this.setState({scrollVal: max_left});
      }else if(this.state.scrollVal - 200 > max_left){
        this.setState({scrollVal:this.state.scrollVal - 200});
      }
    }
    
    resetCarousel(){
      this.setState(initialState);
    }

    generateMultiColor(items){
      let parts = items.length;
      let percent_per_part = (100/parts);
      let rgbaList = []
      for(let i=0; i < items.length; i ++){
        let item = items[i];
        rgbaList.push(`rgba(${item.r}, ${item.g}, ${item.b}, ${item.a}) ${percent_per_part*i}% ${percent_per_part*(i+1)}%`)
      }
      return `linear-gradient(75deg,${rgbaList.join(",")})`
    }

    componentWillUnmount(){
      this.setState(initialState);
    }

    componentWillMount(){
      var self = this;
      this.props.data.forEach(
        function(item){
          if(Object.keys(self.groupedData).indexOf(item['sku_id']) != -1){
            self.groupedData[item['sku_id']].push(item)
          }
          else{
            self.groupedData[item['sku_id']] = [item]
          }
        }
      )

      this.setState({isTouch:window.navigator.isTouch});
      if(this.state.isTouch) console.log("[INFO] Touch interface detected.");
    }

    componentDidMount(){
      var self = this;
      document.addEventListener('reset_request', self.resetCarousel);
    }

    render(){
        
        return(
          <CustomSectionContainer>
            <AbsoluteHiddenCard><ShoppingItem item={this.state.infoObj}/></AbsoluteHiddenCard>
            <CustomButton icon onClick={this.handleMoveLeft}><Icon name='angle left' /></CustomButton>
            <CustomSegmentContainer horizontal>
              <CustomSegmentContainerMoving 
                horizontal 
                id={`${this.props.data[0].type.toLowerCase()}-carousel`} 
                style={ { left: `${this.state.scrollVal}px` } }>
                <CustomHorizontalSegment>
                    <CustomCarouselItemContainer className={`${(this.state.selected===-1 ) ? 'checkered carousel-show-border':' checkered carousel-no-show-border'}`} onClick={(e)=>this.handleMakeup(e,-1, this.props.data[0])} style={{background :`url(${CheckerPattern}) repeat`}}></CustomCarouselItemContainer>
                </CustomHorizontalSegment>
                {
                  Object.values(this.groupedData).map((val)=>{
                    let self = this;
                    if(val.length <= 1){
                      let item = val[0]
                      let index = val[0]['idx']
                      return(
                        <CustomHorizontalSegment>
                          {
                            !this.state.isTouch &&
                            <CustomCarouselItemContainer 
                              className={`${(self.state.selected===index ) ? 'carousel-show-border':'carousel-no-show-border'}`} 
                              onClick={(e)=>self.handleMakeup(e, index, item)} style={{backgroundColor:`rgba(${item.r}, ${item.g}, ${item.b}, ${item.a*100})`}}
                              onMouseEnter={(e)=>{self.props.setCarouselItem(item);self.identifyItem(item);}}
                              onMouseLeave={(e)=>{self.props.setCarouselItem(null);self.identifyItem(null);}}>
                            </CustomCarouselItemContainer>
                          }
                          {
                            this.state.isTouch &&
                            <CustomCarouselItemContainer 
                              className={`${(self.state.selected===index ) ? 'carousel-show-border':'carousel-no-show-border'}`} 
                              onClick={(e)=>{self.handleMakeup(e, index, item);self.props.setCarouselItem(item);self.identifyItem(item);}} style={{backgroundColor:`rgba(${item.r}, ${item.g}, ${item.b}, ${item.a*100})`}}
                              onMouseLeave={(e)=>{self.props.setCarouselItem(null);self.identifyItem(null);}}>  
                            </CustomCarouselItemContainer>
                          }
                        </CustomHorizontalSegment>
                      );
                    }
                    else{
                      //multi color
                      let items = val
                      let index = val[0]['idx']
                      return (
                        <CustomHorizontalSegment>
                          {
                            !this.state.isTouch &&
                            <CustomCarouselItemContainer 
                              className={`${(self.state.selected===index ) ? 'carousel-show-border':'carousel-no-show-border'}`} 
                              style={{background:`${self.generateMultiColor(items)}`}}
                              onClick={(e)=>self.handleMultiMakeup(e, index, items)} 
                              onMouseEnter={(e)=>{self.props.setCarouselItem(items);self.identifyItem(items[0]);}}
                              onMouseLeave={(e)=>{self.props.setCarouselItem(null);self.identifyItem(null);}}>
                            </CustomCarouselItemContainer>
                          }
                          {
                            this.state.isTouch &&
                            <CustomCarouselItemContainer 
                              className={`${(self.state.selected===index ) ? 'carousel-show-border':'carousel-no-show-border'}`} 
                              style={{background:`${self.generateMultiColor(items)}`}}
                              onClick={(e)=>{self.handleMultiMakeup(e, index, items);self.props.setCarouselItem(items);self.identifyItem(items[0]);}}
                              onMouseLeave={(e)=>{self.props.setCarouselItem(null);self.identifyItem(null);}}>
                            </CustomCarouselItemContainer>
                          }
                        </CustomHorizontalSegment>
                      )
                    }
                  })
                }
              </CustomSegmentContainerMoving>
            </CustomSegmentContainer>
            <CustomButton icon onClick={this.handleMoveRight}><Icon name='angle right' /></CustomButton>
          </CustomSectionContainer>
        );
    }
}

Carousel = observer(Carousel);
export default Carousel;
