import * as React from 'react';
import styled from '@emotion/styled';
import {observer, Observer} from 'mobx-react';
import { Modal, Segment, Button, Icon, Form, Image } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import Slider, { Range } from 'rc-slider';
import NudestStore from '../stores/NudestStore';

const CustomModalContent = styled(Modal.Content)``
const ContentContainer = styled('div')``
const NoPaddingSegment = styled(Segment)`
  border: unset !important;
  box-shadow: unset !important;
  margin: 20px 10% !important;
  padding: 0px !important;
  font-family: robo-reg !important;
  font-size: 1.2rem !important;
  text-align: center;
  display:grid !important; 
`
const SliderContainer = styled('div')`
  margin: 25px auto;
  width: 100%;
`
const SliderImage = styled('img')`
  width: 45px;
  height: auto;
  margin: 0px auto 20px auto;
`

const CustomFormGroup = styled(Form.Group)`
  text-align : left !important;
  margin-top: 20px !important;
`

const NudestApiWaitImage = styled('img')`
  width: 200px;
  height: auto;
  margin: 20px auto;
`

const NudestAnalyzedImage = styled('img')`
  max-width: 150px!important;
  max-height: 180px!important;
  margin: 20px auto;
`

const SkinTonesMkr= { 0: "Fair", 1:"Light", 2: "Medium" , 3: "Tan", 4:"Deep", 5: "Rich"}
const SkinTones = { 0: {n:"Fair",v:"fair"}, 1:{n:"Light",v:"light"}, 2: {n:"Medium",v:"medium"} , 3: {n:"Tan",v:"tan"}, 4: {n:"Deep",v:"deep"}, 5: {n:"Rich",v:"rich"}}
const Tanning = { 0: {n:"I usually burn",v:"burn"}, 1: {n:"I tan a little",v:"little"}, 2: {n:"I tan a lot",v:"lot"} , 3: {n:"No change",v:"no"}}
const TanMost = { 0: {n:"December, January, February",v:"winter"}, 1: {n:"March, April, May",v:"spring"}, 2: {n:"June, July, August",v:"summer"} , 3: {n:"September, October, November",v:"autumn"}, 4: {n:"I tan all year round",v:"always"}, 5: {n:"I choose not to tan",v:"no"}}
const TanToday = { 0: {n:"Yes",v:"yes"}, 1: {n:"No",v:"no"}}
const VeinClr = { 0: {n:"Green",v:"green"}, 1: {n:"Purple",v:"purple"}, 2: {n:"Green Purple",v:"greenAndPurple"} , 3: {n:"The color of my veins is not visible",v:"no"}}
const Jewelry = { 0: {n:"Gold",v:"yellow"}, 1: {n:"Silver",v:"purple"}, 2: {n:"Both look just as good on me",v:"all"}}


const Page0 = (props) => {
  return(
    <NoPaddingSegment>
      Match your skin tone to the right shade of foundation with our skin quiz and your selfie.
      Click 'Next' to continue.
    </NoPaddingSegment>
  )
}

const Page1 = observer((props) => {
  return(
    <NoPaddingSegment>
     How fair or rich is your skin tone?
     <SliderContainer>
        {
        (NudestStore.skin_tone != -1) &&
        <SliderImage src={"/SkinTones/"+SkinTones[NudestStore.skin_tone].n+".png"}/>
        }
        <Slider min={0} max={5} defaultValue={0} marks={SkinTonesMkr} step={null} onChange={(v)=>{NudestStore.setSkinTone(v)}}/>
      </SliderContainer>
    </NoPaddingSegment>
  )
});

const Page2 = observer((props) => {
  return(
    <NoPaddingSegment>
      How does your skin react to sun exposure?
      <CustomFormGroup>
        <Form.Radio label={Tanning[0].n} value={0} checked={NudestStore.tanning === 0} onChange={(e, vObj)=>{NudestStore.setTanning(vObj.value)}}/>
        <Form.Radio label={Tanning[1].n} value={1} checked={NudestStore.tanning === 1} onChange={(e, vObj)=>{NudestStore.setTanning(vObj.value)}}/>
        <Form.Radio label={Tanning[2].n} value={2} checked={NudestStore.tanning === 2} onChange={(e, vObj)=>{NudestStore.setTanning(vObj.value)}}/>
        <Form.Radio label={Tanning[3].n} value={3} checked={NudestStore.tanning === 3} onChange={(e, vObj)=>{NudestStore.setTanning(vObj.value)}}/>
      </CustomFormGroup>
    </NoPaddingSegment>
  )
});

const Page3 = observer((props) => {
  return(
    <NoPaddingSegment>
      When do you tan the most?
      <CustomFormGroup>
        <Form.Checkbox label={TanMost[0].n} value={0} checked={NudestStore.tan_most.includes(0)} onClick={(e, vObj)=>{NudestStore.pushToTanMost(vObj.value)}}/>
        <Form.Checkbox label={TanMost[1].n} value={1} checked={NudestStore.tan_most.includes(1)} onClick={(e, vObj)=>{NudestStore.pushToTanMost(vObj.value)}}/>
        <Form.Checkbox label={TanMost[2].n} value={2} checked={NudestStore.tan_most.includes(2)} onClick={(e, vObj)=>{NudestStore.pushToTanMost(vObj.value)}}/>
        <Form.Checkbox label={TanMost[3].n} value={3} checked={NudestStore.tan_most.includes(3)} onClick={(e, vObj)=>{NudestStore.pushToTanMost(vObj.value)}}/>
        <Form.Radio label={TanMost[4].n} value={4} checked={NudestStore.tan_most.includes(4)} onClick={(e, vObj)=>{NudestStore.pushToTanMost(vObj.value)}}/>
        <Form.Radio label={TanMost[5].n} value={5} checked={NudestStore.tan_most.includes(5)} onClick={(e, vObj)=>{NudestStore.pushToTanMost(vObj.value)}}/>
      </CustomFormGroup>
    </NoPaddingSegment>
  )
});

const Page4 = observer((props) => {
  return(
    <NoPaddingSegment>
      Are you tan today?
      <CustomFormGroup>
        <Form.Radio label={TanToday[0].n} value={0} checked={NudestStore.tan_today === 0} onClick={(e, vObj)=>{NudestStore.setTanToday(vObj.value)}}/>
        <Form.Radio label={TanToday[1].n} value={1} checked={NudestStore.tan_today === 1} onClick={(e, vObj)=>{NudestStore.setTanToday(vObj.value)}}/>
      </CustomFormGroup>
    </NoPaddingSegment>
  )
});

const Page5 = observer((props) => {
  return(
    <NoPaddingSegment>
      Take a look at the veins inside your wrist. Which colors do you see?
      <CustomFormGroup>
        <Form.Radio label={VeinClr[0].n} value={0} checked={NudestStore.vein_clr === 0} onClick={(e, vObj)=>{NudestStore.setVeinClr(vObj.value)}}/>
        <Form.Radio label={VeinClr[1].n} value={1} checked={NudestStore.vein_clr === 1} onClick={(e, vObj)=>{NudestStore.setVeinClr(vObj.value)}}/>
        <Form.Radio label={VeinClr[2].n} value={2} checked={NudestStore.vein_clr === 2} onClick={(e, vObj)=>{NudestStore.setVeinClr(vObj.value)}}/>
        <Form.Radio label={VeinClr[3].n} value={3} checked={NudestStore.vein_clr === 3} onClick={(e, vObj)=>{NudestStore.setVeinClr(vObj.value)}}/>
      </CustomFormGroup>
    </NoPaddingSegment>
  )
});

const Page6 = observer((props) => {
  return(
    <NoPaddingSegment>
      Which jewelry color looks best on you?
      <CustomFormGroup>
        <Form.Radio label={Jewelry[0].n} value={0} checked={NudestStore.jewel === 0} onClick={(e, vObj)=>{NudestStore.setJewel(vObj.value)}}/>
        <Form.Radio label={Jewelry[1].n} value={1} checked={NudestStore.jewel === 1} onClick={(e, vObj)=>{NudestStore.setJewel(vObj.value)}}/>
        <Form.Radio label={Jewelry[2].n} value={2} checked={NudestStore.jewel === 2} onClick={(e, vObj)=>{NudestStore.setJewel(vObj.value)}}/>
      </CustomFormGroup>
    </NoPaddingSegment>
  )
});

class Page7 extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount() {
    var self = this;
    fetch(NudestStore.selfie).then(res => res.blob()).then(blob => {
        /** construct data to send */
        let tan_most_temp = [];
        NudestStore.tan_most.forEach( (e) => {
          tan_most_temp.push(TanMost[e].v)
        })

        let out_data  = {
            veinsColor:VeinClr[NudestStore.vein_clr].v,                
            favoriteColor:Jewelry[NudestStore.jewel].v,
            seasons: JSON.stringify(tan_most_temp),
            isTanToday:TanToday[NudestStore.tan_today].v,
            skinReact:Tanning[NudestStore.tanning].v,
            toneFamily:SkinTones[NudestStore.skin_tone].v,
        };
        
        let my_form_data = window.makeFormData(out_data)
        my_form_data.append("image", blob);


        fetch('https://nudemeter-api.herokuapp.com/getToken', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'client_id': '0oa179h58zRoJhtxZ357',
                'client_secret':'tnyQ4HsQegHYjgcqE4vz5qyft5ey1j3C8d2C5cc2'
            }
            }).then((response) => {
                return response.text()
            }).then((data) => {
                fetch('https://nudemeter-api.herokuapp.com/getSkinTone', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': "Bearer " + data,
                    },
                    body: my_form_data
                    }).then((res) => {
                        return res.text();
                    }).then((res) => {
                        NudestStore.setResult(res);
                        NudestStore.setPage(8);
                    }).catch((error) => {self.props.parentProps.closeHandle()} );
            }).catch((error) => {self.props.parentProps.closeHandle()} );
    }).catch((error) => {self.props.parentProps.closeHandle()} );
  }
  
  render(){
    return(
      <NoPaddingSegment>
        Analyzing your skin tone ....
        {
            (NudestStore.results === "") &&
            <NudestApiWaitImage src="/Assets/nudest_loading_small.gif"/>
        }
      </NoPaddingSegment>
    );
  }
}

const Page8 = observer((props) => {
  return(
    <NoPaddingSegment>
      Analysis complete, Continue to view results.
      <NudestAnalyzedImage src={NudestStore.selfie}/> 
    </NoPaddingSegment>
  )
});

var Questionnaire = observer((props) => {
  return(
    <ContentContainer>
      {
        (NudestStore.page == 0 ) &&
        <Page0/>
      }
      {
        (NudestStore.page == 1 ) &&
        <Page1/>
      }
      {
        (NudestStore.page == 2 ) &&
        <Page2/>
      }
      {
        (NudestStore.page == 3 ) &&
        <Page3/>
      }
      {
        (NudestStore.page == 4 ) &&
        <Page4/>
      }
      {
        (NudestStore.page == 5 ) &&
        <Page5/>
      }
      {
        (NudestStore.page == 6 ) &&
        <Page6/>
      }
      {
        (NudestStore.page == 7 ) &&
        <Page7 parentProps={props.parentProps}/>
      }
      {
        (NudestStore.page == 8 ) &&
        <Page8/>
      }

    </ContentContainer>
  )
})

const initialState = {}
export class NudestQuestionnaire extends React.Component {
  constructor(props){
    super(props);
    this.state = initialState;
    this.closeHandle = this.closeHandle.bind(this);
    this.moveBackward = this.moveBackward.bind(this);
    this.moveForward = this.moveForward.bind(this);
    this.validation = this.validation.bind(this);
  }

  validation(){

    if (NudestStore.page === 1) {
        if (NudestStore.skin_tone === -1) {
            window.SERVICES.notify("Select a skin tone value", "ERR");
            return false;
        }
    }

    if (NudestStore.page === 2) {
        if (NudestStore.tanning === -1) {
            window.SERVICES.notify("Select an option", "ERR");
            return false;
        }
    }

    if (NudestStore.page === 3) {
        if (NudestStore.tan_most.length === 0) {
            window.SERVICES.notify("Select an option", "ERR");
            return false;
        }
    }

    if (NudestStore.page === 4) {
        if (NudestStore.tan_today === -1) {
            window.SERVICES.notify("Select an option", "ERR");
            return false;
        }
    }

    if (NudestStore.page === 5) {
        if (NudestStore.vein_clr === -1) {
            window.SERVICES.notify("Select an option", "ERR");
            return false;
        }
    }

    if (NudestStore.page === 6) {
        if (NudestStore.jewel === -1) {
            window.SERVICES.notify("Select an option", "ERR");
            return false;
        }

    }

    return true;
}

  moveBackward(){
    if(NudestStore.page > 0){
      NudestStore.decPage();
    }
    else{
      NudestStore.setPage(0);
    }
  }

  moveForward(){
    if(!this.validation()) return;
    if(NudestStore.page < 7){
      NudestStore.incPage();
    }
    else{
      if(NudestStore.page == 8){
        this.props.closeHandle();
      }
      else{
        return;
      }
    }
  }

  closeHandle(){
    this.props.closeHandle();
  }

  componentDidMount(){
    /** fresh nudest store state */
    NudestStore.cleanSlate();
    
    /** Load image to nudest store */
    NudestStore.setSelfie(window.SERVICES.vmtcore.getBaseImageB64());
  }

  render(){
      return(
        <Modal size="mini" open={true} onClose={this.closeHandle} closeIcon>
          <Modal.Header>Match Your Skin Tone</Modal.Header>
          <CustomModalContent>
            <Questionnaire parentProps={this.props}/>
          </CustomModalContent>
          <Modal.Actions>
            { (NudestStore.page < 7) &&
              <Button onClick={this.moveBackward} icon labelPosition='left'>
                <Icon name='left arrow' />
                Previous
              </Button>
            }
            {
              (NudestStore.page  != 7) &&
              <Button onClick={this.moveForward} icon labelPosition='right'>
                Next
                <Icon name='right arrow' />
              </Button>
            }
          </Modal.Actions>
        </Modal>
      );
  }
}

NudestQuestionnaire = observer(NudestQuestionnaire);
export default NudestQuestionnaire;
