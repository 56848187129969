import {decorate, observable, configure, action, computed} from "mobx";

class MakeupStore {

  Makeup = {
    "foundation": [{
        "idx": 0,
        "sku_id": "FOUNDATION_0",
        "sku_name": "FOUNDATION_0",
        "type": "FOUNDATION",
        "r": 247,
        "g": 201,
        "b": 186,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 1,
        "sku_id": "FOUNDATION_1",
        "sku_name": "FOUNDATION_1",
        "type": "FOUNDATION",
        "r": 240,
        "g": 184,
        "b": 156,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 2,
        "sku_id": "FOUNDATION_2",
        "sku_name": "FOUNDATION_2",
        "type": "FOUNDATION",
        "r": 240,
        "g": 184,
        "b": 156,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 3,
        "sku_id": "FOUNDATION_3",
        "sku_name": "FOUNDATION_3",
        "type": "FOUNDATION",
        "r": 237,
        "g": 184,
        "b": 166,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 4,
        "sku_id": "FOUNDATION_4",
        "sku_name": "FOUNDATION_4",
        "type": "FOUNDATION",
        "r": 224,
        "g": 176,
        "b": 143,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 5,
        "sku_id": "FOUNDATION_5",
        "sku_name": "FOUNDATION_5",
        "type": "FOUNDATION",
        "r": 212,
        "g": 163,
        "b": 140,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 6,
        "sku_id": "FOUNDATION_6",
        "sku_name": "FOUNDATION_6",
        "type": "FOUNDATION",
        "r": 222,
        "g": 173,
        "b": 148,
        "a": 1, 
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 7,
        "sku_id": "FOUNDATION_7",
        "sku_name": "FOUNDATION_7",
        "type": "FOUNDATION",
        "r": 230,
        "g": 166,
        "b": 130,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 8,
        "sku_id": "FOUNDATION_8",
        "sku_name": "FOUNDATION_8",
        "type": "FOUNDATION",
        "r": 214,
        "g": 143,
        "b": 77,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 9,
        "sku_id": "FOUNDATION_9",
        "sku_name": "FOUNDATION_9",
        "type": "FOUNDATION",
        "r": 204,
        "g": 125,
        "b": 64,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 10,
        "sku_id": "FOUNDATION_10",
        "sku_name": "FOUNDATION_10",
        "type": "FOUNDATION",
        "r": 199,
        "g": 125,
        "b": 79,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      },
      {
        "idx": 11,
        "sku_id": "FOUNDATION_11",
        "sku_name": "FOUNDATION_11",
        "type": "FOUNDATION",
        "r": 153,
        "g": 74,
        "b": 25,
        "a": 1,
        "finish_type":"Matte",
        "finish_intensity":1,
        "smoothing_coefficent":1,
        "shininess":1,
        "blending_ratio":1
      }
    ],
    "foundation 2": [{
      "idx": 0,
      "sku_id": "FOUNDATION_0",
      "sku_name": "FOUNDATION_0",
      "type": "FOUNDATION 2",
      "r": 247,
      "g": 201,
      "b": 186,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 1,
      "sku_id": "FOUNDATION_1",
      "sku_name": "FOUNDATION_1",
      "type": "FOUNDATION 2",
      "r": 240,
      "g": 184,
      "b": 156,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 2,
      "sku_id": "FOUNDATION_2",
      "sku_name": "FOUNDATION_2",
      "type": "FOUNDATION 2",
      "r": 240,
      "g": 184,
      "b": 156,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 3,
      "sku_id": "FOUNDATION_3",
      "sku_name": "FOUNDATION_3",
      "type": "FOUNDATION 2",
      "r": 237,
      "g": 184,
      "b": 166,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 4,
      "sku_id": "FOUNDATION_4",
      "sku_name": "FOUNDATION_4",
      "type": "FOUNDATION 2",
      "r": 224,
      "g": 176,
      "b": 143,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 5,
      "sku_id": "FOUNDATION_5",
      "sku_name": "FOUNDATION_5",
      "type": "FOUNDATION 2",
      "r": 212,
      "g": 163,
      "b": 140,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 6,
      "sku_id": "FOUNDATION_6",
      "sku_name": "FOUNDATION_6",
      "type": "FOUNDATION 2",
      "r": 222,
      "g": 173,
      "b": 148,
      "a": 1, 
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 7,
      "sku_id": "FOUNDATION_7",
      "sku_name": "FOUNDATION_7",
      "type": "FOUNDATION 2",
      "r": 230,
      "g": 166,
      "b": 130,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 8,
      "sku_id": "FOUNDATION_8",
      "sku_name": "FOUNDATION_8",
      "type": "FOUNDATION 2",
      "r": 214,
      "g": 143,
      "b": 77,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 9,
      "sku_id": "FOUNDATION_9",
      "sku_name": "FOUNDATION_9",
      "type": "FOUNDATION 2",
      "r": 204,
      "g": 125,
      "b": 64,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 10,
      "sku_id": "FOUNDATION_10",
      "sku_name": "FOUNDATION_10",
      "type": "FOUNDATION 2",
      "r": 199,
      "g": 125,
      "b": 79,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    },
    {
      "idx": 11,
      "sku_id": "FOUNDATION_11",
      "sku_name": "FOUNDATION_11",
      "type": "FOUNDATION 2",
      "r": 153,
      "g": 74,
      "b": 25,
      "a": 1,
      "finish_type":"Matte",
      "finish_intensity":1,
      "smoothing_coefficent":1,
      "shininess":1,
      "blending_ratio":1
    }
  ],
    "blush": [{
        "idx": 0,
        "sku_id": "BLUSH_0",
        "sku_name": "BLUSH_0",
        "type": "BLUSH",
        "r": 250,
        "g": 158,
        "b": 145,
        "a": 1,
        "mask": "blush_1",
        "shimmer_mask" : "blush_shimmer_1",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
      },
      {
        "idx": 1,
        "sku_id": "BLUSH_1",
        "sku_name": "BLUSH_1",
        "type": "BLUSH",
        "r": 245,
        "g": 133,
        "b": 107,
        "a": 1,
        "mask": "blush_2",
        "shimmer_mask" : "blush_shimmer_2",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      },
      {
        "idx": 2,
        "sku_id": "BLUSH_2",
        "sku_name": "BLUSH_2",
        "type": "BLUSH",
        "r": 250,
        "g": 102,
        "b": 125,
        "a": 1,
        "mask": "blush_3",
        "shimmer_mask" : "blush_shimmer_3",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      },
      {
        "idx": 3,
        "sku_id": "BLUSH_3",
        "sku_name": "BLUSH_3",
        "type": "BLUSH",
        "r": 191,
        "g": 71,
        "b": 76,
        "a": 1,
        "mask": "blush_2",
        "shimmer_mask" : "blush_shimmer_2",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      },
      {
        "idx": 4,
        "sku_id": "BLUSH_4",
        "sku_name": "BLUSH_4",
        "type": "BLUSH",
        "r": 175,
        "g": 114,
        "b": 92,
        "a": 1,
        "mask": "blush_1",
        "shimmer_mask" : "blush_shimmer_1",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      },
      {
        "idx": 5,
        "sku_id": "BLUSH_5",
        "sku_name": "BLUSH_5",
        "type": "BLUSH",
        "r": 175,
        "g": 114,
        "b": 92,
        "a": 1,
        "mask": "blush_2",
        "shimmer_mask" : "blush_shimmer_2",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      },
      {
        "idx": 6,
        "sku_id": "BLUSH_6",
        "sku_name": "BLUSH_6",
        "type": "BLUSH",
        "r": 216,
        "g": 169,
        "b": 165,
        "a": 1,
        "mask": "blush_3",
        "shimmer_mask" : "blush_shimmer_3",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      },
      {
        "idx": 7,
        "sku_id": "BLUSH_7",
        "sku_name": "BLUSH_7",
        "type": "BLUSH",
        "r": 242,
        "g": 181,
        "b": 150,
        "a": 1,
        "mask": "blush_1",
        "shimmer_mask" : "blush_shimmer_1",
        "shimmer_intensity" : 0.2,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0
      }
    ],
    "highlighter": [{
        "idx": 0,
        "sku_id": "HIGHLIGHTER_0",
        "sku_name": "Gold",
        "type": "HIGHLIGHTER",
        "r": 252,
        "g": 226,
        "b": 117,
        "a": 0.35,
        "mask": "highlighter_1",
        "shimmer_mask" : "highlighter_shimmer",
        "shimmer_intensity" : 1,
        
      },
      {
        "idx": 1,
        "sku_id": "HIGHLIGHTER_1",
        "sku_name": "Pink",
        "type": "HIGHLIGHTER",
        "r": 241,
        "g": 144,
        "b": 227,
        "a": 0.46,
        "mask": "highlighter_1",
        "shimmer_mask" : "highlighter_shimmer",
        "shimmer_intensity" : 1,
      },
      {
        "idx": 2,
        "sku_id": "HIGHLIGHTER_2",
        "sku_name": "Purple",
        "type": "HIGHLIGHTER",
        "r": 191,
        "g": 139,
        "b": 239,
        "a": 0.5,
        "mask": "highlighter_1"
      },
      {
        "idx": 3,
        "sku_id": "HIGHLIGHTER_3",
        "sku_name": "Orange",
        "type": "HIGHLIGHTER",
        "r": 217,
        "g": 146,
        "b": 41,
        "a": 0.41,
        "mask": "highlighter_1"
      },
      {
        "idx": 4,
        "sku_id": "HIGHLIGHTER_4",
        "sku_name": "Silver",
        "type": "HIGHLIGHTER",
        "r": 192,
        "g": 192,
        "b": 192,
        "a": 0.5,
        "mask": "highlighter_1"
      }
    ],
    "contour":[{
      "idx": 0,
      "sku_id": "CONTOUR_0",
      "sku_name": "CONTOUR_0",
      "type": "CONTOUR",
      "r": 185,
      "g": 144,
      "b": 119,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_2",
    },
    {
      "idx": 1,
      "sku_id": "CONTOUR_1",
      "sku_name": "CONTOUR_1",
      "type": "CONTOUR",
      "r": 225,
      "g": 211,
      "b": 182,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_3",
    },
    {
      "idx": 2,
      "sku_id": "CONTOUR_2",
      "sku_name": "CONTOUR_2",
      "type": "CONTOUR",
      "r": 183,
      "g": 141,
      "b": 115,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_2",
    },
    {
      "idx": 3,
      "sku_id": "CONTOUR_3",
      "sku_name": "CONTOUR_3",
      "type": "CONTOUR",
      "r": 228,
      "g": 211,
      "b": 182,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_3",
    },
    {
      "idx": 4,
      "sku_id": "CONTOUR_4",
      "sku_name": "CONTOUR_4",
      "type": "CONTOUR",
      "r": 180,
      "g": 137,
      "b": 110,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_2",
    },
    {
      "idx": 5,
      "sku_id": "CONTOUR_5",
      "sku_name": "CONTOUR_5",
      "type": "CONTOUR",
      "r": 158,
      "g": 91,
      "b": 56,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_3",
    },
    {
      "idx": 6,
      "sku_id": "CONTOUR_6",
      "sku_name": "CONTOUR_6",
      "type": "CONTOUR",
      "r": 118,
      "g": 83,
      "b": 66,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_3",
    },
    {
      "idx": 7,
      "sku_id": "CONTOUR_7",
      "sku_name": "CONTOUR_7",
      "type": "CONTOUR",
      "r": 218,
      "g": 142,
      "b": 85,
      "a": 0.4,
      "mask": "bronzer_fullface_contour_2",
    }
    ],
    "bronzer": [{
      "idx": 0,
      "sku_id": "BRONZER_0",
      "sku_name": "Bronzer 0",
      "type": "BRONZER",
      "r": 195,
      "g": 145,
      "b": 101,
      "a": 0.6,
      "mask": "bronzer_fullface",
    },
    {
      "idx": 1,
      "sku_id": "BRONZER_1",
      "sku_name": "Bronzer 1",
      "type": "BRONZER",
      "r": 172,
      "g": 119,
      "b": 67,
      "a": 0.6,
      "mask": "bronzer_contour",
    },
    {
      "idx": 2,
      "sku_id": "BRONZER_2",
      "sku_name": "Bronzer 2",
      "type": "BRONZER",
      "r": 186,
      "g": 113,
      "b": 49,
      "a": 0.6,
      "mask": "bronzer_sunkiss",
    },
    {
      "idx": 3,
      "sku_id": "BRONZER_3",
      "sku_name": "Bronzer 3",
      "type": "BRONZER",
      "r": 139,
      "g": 83,
      "b": 46,
      "a": 0.6,
      "mask": "bronzer_contour",
    },
    {
      "idx": 4,
      "sku_id": "BRONZER_4",
      "sku_name": "Bronzer 4",
      "type": "BRONZER",
      "r": 81,
      "g": 43,
      "b": 28,
      "a": 0.6,
      "mask": "bronzer_fullface",
    },
    {
      "idx": 5,
      "sku_id": "BRONZER_5",
      "sku_name": "Bronzer 5",
      "type": "BRONZER",
      "r": 226,
      "g": 114,
      "b": 91,
      "a": 0.6,
      "mask": "bronzer_sunkiss",
    },
    {
      "idx": 6,
      "sku_id": "BRONZER_6",
      "sku_name": "Bronzer 6",
      "type": "BRONZER",
      "r": 216,
      "g": 77,
      "b": 57,
      "a": 0.6,
      "mask": "bronzer_fullface",
    }
    ],
    "lipstick": [
      // {
      //   "idx": 0,
      //   "sku_id": "LIPSTICK_0",
      //   "sku_name": "LIPSTICK_0",
      //   "type": "LIPSTICK",
      //   "r": 96,
      //   "g": 40,
      //   "b": 38,
      //   "a": 0.5,
      //   "finish_intensity": 0,
      //   "finish_type" : 0,
      //   "shimmer_mask" : "lipShimmer_1",
      //   "shimmer_intensity" : 0.75,
      // },
      // {
      //   "idx": 1,
      //   "sku_id": "LIPSTICK_1",
      //   "sku_name": "LIPSTICK_1",
      //   "type": "LIPSTICK",
      //   "r": 126,
      //   "g": 34,
      //   "b": 35,
      //   "a": 0.5,
      //   "finish_intensity": 0,
      //   "finish_type" : 0,
      //   "glitter_mask" : "lipGlitter_2",
      //   "glitter_intensity": 0.5,
      // },
      // {
      //   "idx": 2,
      //   "sku_id": "LIPSTICK_2",
      //   "sku_name": "LIPSTICK_2",
      //   "type": "LIPSTICK",
      //   "r": 146,
      //   "g": 55,
      //   "b": 40,
      //   "a": 0.5,
      //   "finish_intensity": 0,
      //   "finish_type" : 0,
      //   "glitter_mask" : "lipGlitter_2",
      //   "glitter_intensity": 0.5,
      //   "shimmer_mask" : "lipShimmer_1",
      //   "shimmer_intensity" : 0.75,
      // },
      {
        "idx": 3,
        "sku_id": "LIPSTICK_3",
        "sku_name": "LIPSTICK_3",
        "type": "LIPSTICK",
        "r": 92,
        "g": 30,
        "b": 20,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 4,
        "sku_id": "LIPSTICK_4",
        "sku_name": "LIPSTICK_4",
        "type": "LIPSTICK",
        "r": 160,
        "g": 32,
        "b": 47,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 5,
        "sku_id": "LIPSTICK_5",
        "sku_name": "LIPSTICK_5",
        "type": "LIPSTICK",
        "r": 96,
        "g": 40,
        "b": 38,
        "a": 0.5,
        "finish_intensity": 0.5,
        "finish_type" : 0
      },
      {
        "idx": 6,
        "sku_id": "LIPSTICK_6",
        "sku_name": "LIPSTICK_6",
        "type": "LIPSTICK",
        "r": 160,
        "g": 32,
        "b": 47,
        "a": 0.5,
        "finish_intensity": 0.5,
        "finish_type" : 0
      },
      {
        "idx": 7,
        "sku_id": "LIPSTICK_7",
        "sku_name": "LIPSTICK_7",
        "type": "LIPSTICK",
        "r": 247,
        "g": 105,
        "b": 242,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 8,
        "sku_id": "LIPSTICK_8",
        "sku_name": "LIPSTICK_8",
        "type": "LIPSTICK",
        "r": 255,
        "g": 5,
        "b": 115,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 9,
        "sku_id": "LIPSTICK_9",
        "sku_name": "LIPSTICK_9",
        "type": "LIPSTICK",
        "r": 255,
        "g": 69,
        "b": 115,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 10,
        "sku_id": "LIPSTICK_10",
        "sku_name": "LIPSTICK_10",
        "type": "LIPSTICK",
        "r": 255,
        "g": 38,
        "b": 145,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 11,
        "sku_id": "LIPSTICK_11",
        "sku_name": "LIPSTICK_11",
        "type": "LIPSTICK",
        "r": 87,
        "g": 0,
        "b": 139,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 12,
        "sku_id": "LIPSTICK_12",
        "sku_name": "LIPSTICK_12",
        "type": "LIPSTICK",
        "r": 232,
        "g": 0,
        "b": 163,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      },
      {
        "idx": 13,
        "sku_id": "LIPSTICK_13",
        "sku_name": "LIPSTICK_13",
        "type": "LIPSTICK",
        "r": 176,
        "g": 112,
        "b": 102,
        "a": 0.5,
        "finish_intensity": 0,
        "finish_type" : 0
      }
    ],
    "lipliner": [
      {
          "idx": 0,
          "sku_id": "vll-lush",
          "sku_name": "Lush",
          "type": "LIPLINER",
          "r": 90,
          "g": 45,
          "b": 34,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 1,
          "sku_id": "vll-boysenberry",
          "sku_name": "Boysenberry",
          "type": "LIPLINER",
          "r": 88,
          "g": 34,
          "b": 38,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 2,
          "sku_id": "vll-babe",
          "sku_name": "Babe",
          "type": "LIPLINER",
          "r": 167,
          "g": 81,
          "b": 91,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 3,
          "sku_id": "vll-roseate",
          "sku_name": "Roseate",
          "type": "LIPLINER",
          "r": 252,
          "g": 47,
          "b": 87,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 4,
          "sku_id": "vll-queen",
          "sku_name": "Queen",
          "type": "LIPLINER",
          "r": 250,
          "g": 160,
          "b": 157,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 5,
          "sku_id": "vll-bare",
          "sku_name": "Bare",
          "type": "LIPLINER",
          "r": 216,
          "g": 131,
          "b": 120,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 6,
          "sku_id": "vll-drama",
          "sku_name": "Drama",
          "type": "LIPLINER",
          "r": 251,
          "g": 55,
          "b": 86,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 7,
          "sku_id": "vll-brulee",
          "sku_name": "Brûlée",
          "type": "LIPLINER",
          "r": 242,
          "g": 128,
          "b": 135,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 8,
          "sku_id": "vll-lady",
          "sku_name": "Lady",
          "type": "LIPLINER",
          "r": 80,
          "g": 22,
          "b": 21,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 9,
          "sku_id": "vll-kisses",
          "sku_name": "Kisses",
          "type": "LIPLINER",
          "r": 200,
          "g": 134,
          "b": 158,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 10,
          "sku_id": "vll-raspberry",
          "sku_name": "Raspberry",
          "type": "LIPLINER",
          "r": 159,
          "g": 49,
          "b": 81,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 11,
          "sku_id": "vll-rush",
          "sku_name": "Rush",
          "type": "LIPLINER",
          "r": 250,
          "g": 123,
          "b": 143,
          "a": 0.25,
          "mask": "lipliner_1"
      },
      {
          "idx": 12,
          "sku_id": "vll-posh",
          "sku_name": "Posh",
          "type": "LIPLINER",
          "r": 244,
          "g": 153,
          "b": 133,
          "a": 0.25,
          "mask": "lipliner_1"
      }
    ],
    "eyeshadow": [{
        "idx": 0,
        "sku_id": "EYESHADOW_0",
        "sku_name": "EYESHADOW_0",
        "type": "EYESHADOW",
        "r": 192,
        "g": 130,
        "b": 73,
        "a": 0.65,
        "mask": "eyeshadow_1",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 1,
        "sku_id": "EYESHADOW_1",
        "sku_name": "EYESHADOW_1",
        "type": "EYESHADOW",
        "r": 102,
        "g": 173,
        "b": 193,
        "a": 0.65,
        "mask": "eyeshadow_2",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 2,
        "sku_id": "EYESHADOW_1",
        "sku_name": "EYESHADOW_1",
        "type": "EYESHADOW",
        "r": 20,
        "g": 162,
        "b": 210,
        "a": 0.65,
        "mask": "eyeshadow_3",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 3,
        "sku_id": "EYESHADOW_1",
        "sku_name": "EYESHADOW_1",
        "type": "EYESHADOW",
        "r": 48,
        "g": 55,
        "b": 83,
        "a": 0.65,
        "mask": "eyeshadow_4",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 4,
        "sku_id": "EYESHADOW_4",
        "sku_name": "EYESHADOW_4",
        "type": "EYESHADOW",
        "r": 246,
        "g": 198,
        "b": 178,
        "a": 0.65,
        "mask": "eyeshadow_5",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 5,
        "sku_id": "EYESHADOW_4",
        "sku_name": "EYESHADOW_4",
        "type": "EYESHADOW",
        "r": 210,
        "g": 164,
        "b": 164,
        "a": 0.65,
        "mask": "eyeshadow_6",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 6,
        "sku_id": "EYESHADOW_4",
        "sku_name": "EYESHADOW_4",
        "type": "EYESHADOW",
        "r": 101,
        "g": 63,
        "b": 54,
        "a": 0.65,
        "mask": "eyeshadow_7",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 7,
        "sku_id": "EYESHADOW_4",
        "sku_name": "EYESHADOW_4",
        "type": "EYESHADOW",
        "r": 225,
        "g": 117,
        "b": 189,
        "a": 0.65,
        "mask": "eyeshadow_8",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 8,
        "sku_id": "EYESHADOW_4",
        "sku_name": "EYESHADOW_4",
        "type": "EYESHADOW",
        "r": 246,
        "g": 198,
        "b": 178,
        "a": 0.65,
        "mask": "eyeshadow_9",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 9,
        "sku_id": "EYESHADOW_9",
        "sku_name": "EYESHADOW_9",
        "type": "EYESHADOW",
        "r": 163,
        "g": 120,
        "b": 101,
        "a": 0.65,
        "mask": "eyeshadow_10",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 10,
        "sku_id": "EYESHADOW_9",
        "sku_name": "EYESHADOW_9",
        "type": "EYESHADOW",
        "r": 91,
        "g": 97,
        "b": 113,
        "a": 0.65,
        "mask": "eyeshadow_11",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 11,
        "sku_id": "EYESHADOW_11",
        "sku_name": "EYESHADOW_11",
        "type": "EYESHADOW",
        "r": 227,
        "g": 118,
        "b": 171,
        "a": 0.65,
        "mask": "eyeshadow_12",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 12,
        "sku_id": "EYESHADOW_11",
        "sku_name": "EYESHADOW_12",
        "type": "EYESHADOW",
        "r": 115,
        "g": 154,
        "b": 153,
        "a": 0.65,
        "mask": "eyeshadow_13",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 13,
        "sku_id": "EYESHADOW_13",
        "sku_name": "EYESHADOW_13",
        "type": "EYESHADOW",
        "r": 154,
        "g": 145,
        "b": 146,
        "a": 0.65,
        "mask": "eyeshadow_14",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 14,
        "sku_id": "EYESHADOW_13",
        "sku_name": "EYESHADOW_13",
        "type": "EYESHADOW",
        "r": 89,
        "g": 78,
        "b": 84,
        "a": 0.65,
        "mask": "eyeshadow_15",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 15,
        "sku_id": "EYESHADOW_15",
        "sku_name": "EYESHADOW_15",
        "type": "EYESHADOW",
        "r": 221,
        "g": 156,
        "b": 199,
        "a": 0.65,
        "mask": "eyeshadow_16",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 16,
        "sku_id": "EYESHADOW_15",
        "sku_name": "EYESHADOW_15",
        "type": "EYESHADOW",
        "r": 166,
        "g": 117,
        "b": 161,
        "a": 0.65,
        "mask": "eyeshadow_17",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      },
      {
        "idx": 17,
        "sku_id": "EYESHADOW_15",
        "sku_name": "EYESHADOW_15",
        "type": "EYESHADOW",
        "r": 65,
        "g": 45,
        "b": 57,
        "a": 0.65,
        "mask": "eyeshadow_18",
        "shimmer_mask" : "eyeshadow_shimmer",
        "shimmer_intensity" : 1,
        "shimmer_r": 0,
        "shimmer_g": 0,
        "shimmer_b": 0,
        "shimmer_a": 0,
      }
    ],
    "eyelash": [{
        "idx": 0,
        "sku_id": "EYELASH_0",
        "sku_name": "EYELASH_0",
        "type": "EYELASH",
        "r": 35,
        "g": 31,
        "b": 32,
        "a": 0.5,
        "mask": "eyelash_1"
      },
      {
        "idx": 1,
        "sku_id": "EYELASH_1",
        "sku_name": "EYELASH_1",
        "type": "EYELASH",
        "r": 128,
        "g": 0,
        "b": 32,
        "a": 0.5,
        "mask": "eyelash_4"
      },
      {
        "idx": 2,
        "sku_id": "EYELASH_2",
        "sku_name": "EYELASH_2",
        "type": "EYELASH",
        "r": 77,
        "g": 44,
        "b": 0,
        "a": 0.5,
        "mask": "eyelash_7"
      },
      {
        "idx": 3,
        "sku_id": "EYELASH_3",
        "sku_name": "EYELASH_3",
        "type": "EYELASH",
        "r": 3,
        "g": 0,
        "b": 1,
        "a": 0.5,
        "mask": "eyelash_11"
      },
      {
        "idx": 4,
        "sku_id": "EYELASH_4",
        "sku_name": "EYELASH_4",
        "type": "EYELASH",
        "r": 0,
        "g": 7,
        "b": 240,
        "a": 0.5,
        "mask": "eyelash_13"
      }
    ],
    "eyeliner": [{
        "idx": 0,
        "sku_id": "EYELINER_0",
        "sku_name": "EYELINER_0",
        "type": "EYELINER",
        "r": 0,
        "g": 0,
        "b": 9,
        "a": 0.5,
        "mask": "eyeliner_2"
      },
      {
        "idx": 1,
        "sku_id": "EYELINER_1",
        "sku_name": "EYELINER_1",
        "type": "EYELINER",
        "r": 78,
        "g": 56,
        "b": 1,
        "a": 0.5,
        "mask": "eyeliner_3"
      },
      {
        "idx": 2,
        "sku_id": "EYELINER_2",
        "sku_name": "EYELINER_2",
        "type": "EYELINER",
        "r": 94,
        "g": 62,
        "b": 17,
        "a": 0.5,
        "mask": "eyeliner_4"
      },
      {
        "idx": 3,
        "sku_id": "EYELINER_3",
        "sku_name": "EYELINER_3",
        "type": "EYELINER",
        "r": 4,
        "g": 0,
        "b": 0,
        "a": 0.5,
        "mask": "eyeliner_6"
      },
      {
        "idx": 4,
        "sku_id": "EYELINER_4",
        "sku_name": "EYELINER_4",
        "type": "EYELINER",
        "r": 0,
        "g": 34,
        "b": 63,
        "a": 0.5,
        "mask": "eyeliner_8"
      },
      {
        "idx": 5,
        "sku_id": "EYELINER_5",
        "sku_name": "EYELINER_5",
        "type": "EYELINER",
        "r": 70,
        "g": 69,
        "b": 71,
        "a": 0.5,
        "mask": "eyeliner_10"
      },
      {
        "idx": 6,
        "sku_id": "EYELINER_6",
        "sku_name": "EYELINER_6",
        "type": "EYELINER",
        "r": 255,
        "g": 255,
        "b": 255,
        "a": 0.5,
        "mask": "eyeliner_11"
      }
    ],
    "eyebrow":[
      {
        "idx": 0,
        "sku_id": "EYEBROW_0",
        "sku_name": "Blonde Powder",
        "type": "EYEBROW",
        "r": 167,
        "g": 133,
        "b": 106,
        "a": 0.5,
        "mask": "eyebrow_powder_5"
      },
      {
        "idx": 1,
        "sku_id": "EYEBROW_1",
        "sku_name": "Auburn Powder",
        "type": "EYEBROW",
        "r": 129,
        "g": 50,
        "b": 46,
        "a": 0.5,
        "mask": "eyebrow_powder_5"
      },
      {
        "idx": 2,
        "sku_id": "Excellence",
        "sku_name": "Celebrity Brow Kit",
        "type": "EYEBROW",
        "r": 233,
        "g": 173,
        "b": 137,
        "a": 0.5,
        "mask": "eyebrow_creme_lowerUpper_1"
      },
      {
        "idx": 3,
        "sku_id": "Eloquence",
        "sku_name": "Celebrity Brow Kit",
        "type": "EYEBROW",
        "r": 229,
        "g": 182,
        "b": 164,
        "a": 0.5,
        "mask": "eyebrow_creme_lowerUpper_1"
      },
      {
        "idx": 4,
        "sku_id": "EYEBROW_4",
        "sku_name": "Soft Black Powder",
        "type": "EYEBROW",
        "r": 15,
        "g": 0,
        "b": 0,
        "a": 0.5,
        "mask": "eyebrow_powder"
      },
      {
        "idx": 5,
        "sku_id": "EYEBROW_5",
        "sku_name": "Rich Pomatum",
        "type": "EYEBROW",
        "r": 35,
        "g": 18,
        "b": 11,
        "a": 0.5,
        "mask": "eyebrow_powder"
      }
    ]
  }

  Products = {}

  // sku_id
  ShoppingCart = observable({
    foundation: "",
    "foundation 2": "",
    blush: "",
    lipstick: "",
    lipliner:"",
    highlighter: "",
    bronzer: "",
    contour: "",
    eyeliner: "",
    eyeshadow: "",
    eyelash: "",
    eyebrow: "",
  })
  
  get foundation(){return this.Makeup.foundation;}
  get foundation2(){return this.Makeup["foundation 2"];}
  
  get blush(){return this.Makeup.blush;}
  
  get highlighter(){return this.Makeup.highlighter;}

  get bronzer(){return this.Makeup.bronzer;}

  get contour() {return this.Makeup.contour;}

  get lipliner(){return this.Makeup.lipliner;}
  
  get lipstick(){return this.Makeup.lipstick;}
  
  get eyeshadow(){return this.Makeup.eyeshadow;}
  
  get eyelash(){return this.Makeup.eyelash;}
  
  get eyeliner(){return this.Makeup.eyeliner;}
  
  get eyebrow(){return this.Makeup.eyebrow;}
  
  get ShoppingCartMatchingProducts() {
    let products = []
    Object.keys(this.ShoppingCart).map((category)=>{
      if(this.ShoppingCart[category] !== ""){
        if(this.Products.hasOwnProperty(category)  && this.Products[category].hasOwnProperty(this.ShoppingCart[category])){
          products.push(this.Products[category][this.ShoppingCart[category]])
        }
      }
    });
    return products;
  }

  getShoppingCartMatchingProductSingle(sku_id, category) {
    let product = null;
    if(this.Products.hasOwnProperty(category.toLowerCase())  && this.Products[category.toLowerCase()].hasOwnProperty(sku_id)){
      if(this.ShoppingCart[category.toLowerCase()] !== sku_id){
        product = this.Products[category.toLowerCase()][sku_id]
      }
    }
    return product;
  }
  
  getShoppingCartSelection(category){
    return this.ShoppingCart[category.toLowerCase()]
  }

  setShoppingCartSelection(category, value){
    this.ShoppingCart[category.toLowerCase()] = value
  }

  removeFromList(sku_id){
    Object.keys(this.ShoppingCart).map((category)=>{
      if(this.ShoppingCart[category] !== "" && this.ShoppingCart[category] === sku_id){
        this.ShoppingCart[category] = "";
      }
    })
  }

}

decorate(MakeupStore, {
  
  foundation : computed,
  //"foundation 2" : computed,
  blush : computed,
  highlighter : computed,
  bronzer : computed,
  contour : computed,
  lipliner: computed,
  lipstick : computed,
  eyeshadow : computed,
  eyelash : computed,
  eyeliner : computed,
  eyebrow : computed,
  ShoppingCartMatchingProducts : computed,
  getShoppingCartMatchingProductSingle: action,
  getShoppingCartSelection : action,
  setShoppingCartSelection : action
  
})



export default new MakeupStore();
