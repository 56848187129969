import * as React from 'react';
import styled from '@emotion/styled';
import {LoaderView, MakeupAlphaControl} from '.'
import {observer} from 'mobx-react';
import { Button, Segment, Icon, Card } from 'semantic-ui-react'
import AppStore from '../stores/AppStore';
import $ from 'jquery';
import 'jqueryui';

const CustomContainer = styled(Segment)`
  margin: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border: unset !important;
  box-shadow: unset !important;
  background-color: var(--live-view-container-bg) !important;
`

const CustomButtonGroup = styled('div')`
  text-align: center !important;
`
const CustomButton = styled(Button)`
  position:relative !important;
  z-index:10 !important;
`
const SimpleButton = styled(CustomButton)`
  color: var(--live-view-ctrl-btn-txt-A) !important;
  background: var(--live-view-ctrl-btn-bg) !important;
  &:hover{
      color: var(--live-view-ctrl-btn-txt-B) !important;
      background: var(--live-view-ctrl-btn-bg-hover) !important;
  }
`

const SimpleButton2 = styled(CustomButton)`
  position: absolute !important;
  top: 1%;
  left: 1%;
  padding: 0.1em 0.2em;
  background: unset !important;
  color: var(--live-view-ctrl-btn-txt-A) !important;
  &:hover{
      color: var(--live-view-ctrl-btn-txt-B) !important;
  }
`

const CloseButton = styled(CustomButton)`
  position: absolute !important;
  top: 1%;
  right: 1%;
  color: var(--live-view-close-btn-txt-A) !important;
  background: var(--live-view-close-btn-bg) !important;
  &:hover{
      color: var(--live-view-close-btn-txt-B) !important;
      background: var(--live-view-close-btn-bg-hover) !important;
  }
`
const CustomCanvas = styled('canvas')`
  display : none;
`
const CustomCard = styled(Card)`
  margin: auto !important;
  width: fit-content !important;
  position: relative !important;
  background-color: var(--live-view-card-bg) !important;
  box-shadow: 0 1px 6px 0 var(--live-view-card-box-shadow-part-A-clr), 0 0 1px 1px var(--live-view-card-box-shadow-part-B-clr) !important;
`
const EmptySplitterContainer = styled('div')``

const SplitterHandleBarContainer = styled('div')`
  position: absolute;
  width: 20px;
  height: 100%;
  background: var(--live-view-splitter-pole-bg-bg) !important;
  left: calc(50% - 13px);
  top: 0px;
  z-index: 4;
`
const SplitterHandleBarContainerSlider = styled('div')`
  width: 4px;
  height: 100%;
  background: var(--live-view-splitter-pole-bg) !important;
  margin: auto;
  z-index: 3;
`
const SplitterHandleBarContainerHandleIcon = styled('div')`
  position: absolute;
  height: 50px;
  width: 20px;
  top:calc(70%);
  left: calc(50% - 10px);
  background-size: 28px;
  background-repeat: no-repeat;
  background-image: url("/Assets/splitter_b.png");
  background-position: center;
  background-color: var(--live-view-splitter-pole-handle-bg);
  border-radius: 3px;
  cursor: grabbing;
  z-index: 3;
`
const SplitterImageContainer = styled('div')`
  background-repeat: no-repeat;
  height: fit-content;
  width: 50%;
  position: absolute !important;
  overflow: hidden !important;
  `


const MAX_ZOOM = 5;

const initialState = {
  zoomValue : 0,
  isSplitterActive : false,
  hasMultiCamera : false,
}

export class LiveView extends React.Component {
    constructor(props){
      super(props);
      this.state = initialState
      this.livePageLoad = this.livePageLoad.bind(this);
      this.modelPageLoad = this.modelPageLoad.bind(this);
      this.uploadedImagePageLoad = this.uploadedImagePageLoad.bind(this);
      this.returnToMainMenu = this.returnToMainMenu.bind(this);
      this.resetViewParams = this.resetViewParams.bind(this);
      this.zoomIn = this.zoomIn.bind(this);
      this.zoomOut = this.zoomOut.bind(this);
      this.downloadImage = this.downloadImage.bind(this);
      this.toggleSplitter = this.toggleSplitter.bind(this);
      this.takeSnap = this.takeSnap.bind(this);
      this.noFaceDetectionHandle = this.noFaceDetectionHandle.bind(this);
    }

    livePageLoad(){
      var self = this;
      /** stream video to base canvas */
      window.SERVICES.video.init('video','canvas_base');
      /** start the video stream */
      window.SERVICES.video.start(
        /** callback after video starts */
        function(){
          window.SERVICES.video.adjustSize();
          window.SERVICES.vmtcore.resetDetection();
          /** process frames from base canvas and display output on final canvas */
          window.SERVICES.vmtcore.setSplitterCanvasId('canvas_splitter');
          window.SERVICES.vmtcore.init('canvas_base','canvas_final');
          /**
           * live stream does not have to render makeup
           * separately, the following line will indicate
           * this information to vmtcore instance
           * 
           */
          window.SERVICES.vmtcore.setIsLive(true);
          /** end loader page */
          AppStore.setLoaderStatus(false);

          window.SERVICES.video.registerDrawCb(
            /** register a recursive callback */
            function(){
              window.SERVICES.vmtcore.renderLive();
            }
          );
          window.SERVICES.video.draw();
        },
        function(){
          self.returnToMainMenu();
        }

      );
    }

    modelPageLoad(){
      let imageURI = `/Models/${AppStore.currentModel}.jpg`;
      var self = this;
      window.SERVICES.image.registerDrawCb(
        /** register a callback that runs on image load */
        function(){
          window.vmt_module._setModelImageBypass(parseInt(AppStore.currentModel));
          window.vmt_module._setMaxIterations(7);
          window.SERVICES.vmtcore.resetDetection();
          window.SERVICES.vmtcore.setSplitterCanvasId('canvas_splitter');
          window.SERVICES.vmtcore.init('canvas_base','canvas_final');
          window.SERVICES.vmtcore.setIsLive(false);
          window.SERVICES.vmtcore.getDetection();
          window.SERVICES.vmtcore.render();
          self.noFaceDetectionHandle();
        }
      );
      window.SERVICES.image.init('canvas_base',imageURI);
    }

    uploadedImagePageLoad(){
      let imageURI = AppStore.currentImage;
      var self = this;
      window.SERVICES.image.registerDrawCb(
        /** register a callback that runs on image load */
        function(){
          window.vmt_module._setModelImageBypass(0);
          window.vmt_module._setMaxIterations(7);
          window.SERVICES.vmtcore.resetDetection();
          window.SERVICES.vmtcore.setSplitterCanvasId('canvas_splitter');
          window.SERVICES.vmtcore.init('canvas_base','canvas_final');
          window.SERVICES.vmtcore.setIsLive(false);
          window.SERVICES.vmtcore.getDetection();
          self.noFaceDetectionHandle();

          if(window.SERVICES.vmtcore.rotations_made > 0){
            let vmtcore = window.SERVICES.vmtcore;
            let image = window.SERVICES.image;
            if(window.SERVICES.vmtcore.rotations_made %2 === 0){
              vmtcore.canvas_i.width = image.img.width;
              vmtcore.canvas_i.height = image.img.height;
            }
            else{
              vmtcore.canvas_i.width = image.img.height;
              vmtcore.canvas_i.height = image.img.width;
            }
            vmtcore.ctx_i.clearRect(0,0,vmtcore.canvas_i.width,vmtcore.canvas_i.height)
            vmtcore.ctx_i.save()
            vmtcore.ctx_i.translate(vmtcore.canvas_i.width/2, vmtcore.canvas_i.height/2)
            vmtcore.ctx_i.rotate(vmtcore.rotations_made * (-1 * Math.PI)/2)
            vmtcore.ctx_i.drawImage(image.img, -image.img.width/2, -image.img.height/2)
            vmtcore.ctx_i.restore(); 
            if(image.cb) image.cb();
          }
          else{
            window.SERVICES.vmtcore.render();
          }

        }
      );
      window.SERVICES.image.init('canvas_base',imageURI);
    }

    zoomIn(){
      if(this.state.zoomValue >= 0 && this.state.zoomValue < MAX_ZOOM){
        let newZoomValue = this.state.zoomValue + 1;
        /** set state */
        this.setState({zoomValue: newZoomValue});
        /** reflect this zoom value update in vmtcore instance */
        window.SERVICES.vmtcore.zoomTo(newZoomValue);

        if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
          window.SERVICES.vmtcore.zoomLiveRoutine();
        }else{
          window.SERVICES.vmtcore.render();
        }
      }
    }

    resetViewParams(){
      /**
       * remove all makeup
       * reset zoom to '0'
       * render updates if necessary
       */
      window.vmt_module._resetAllMakeup();
      window.SERVICES.vmtcore.zoomTo(0);
      this.setState({zoomValue: 0});
      if(this.state.isSplitterActive) this.toggleSplitter();
      if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
        window.SERVICES.vmtcore.zoomLiveRoutine();
      }else{
        window.SERVICES.vmtcore.render();
      }
      document.dispatchEvent(new CustomEvent('reset_request'));
      document.dispatchEvent(new CustomEvent('reset_request_2'));
      document.dispatchEvent(new CustomEvent('reset_request_3'));
    }

    zoomOut(){
      if(this.state.zoomValue >0 && this.state.zoomValue <= MAX_ZOOM){
        let newZoomValue = this.state.zoomValue - 1;
        /** set state */
        this.setState({zoomValue: newZoomValue});
        /** reflect this zoom value update in vmtcore instance */
        window.SERVICES.vmtcore.zoomTo(newZoomValue);

        if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
          window.SERVICES.vmtcore.zoomLiveRoutine();
        }else{
          window.SERVICES.vmtcore.render();
        }
      }
    }

    takeSnap(){
      /** get single image frame from live stream */
      let imageURI = window.SERVICES.vmtcore.getBaseImageB64();
      /** 
       * set app state to look like an uploaded image
       *  - stop video
       *  - toggle isSnappedPhoto in AppStore
       *  - set current page as PHOTO in AppStore
       */
      AppStore.setCurrentPage(AppStore.PagesEnum.PHOTO);
      
      var self = this;
      window.SERVICES.image.registerDrawCb(
        /** register a callback that runs on image load */
        ()=>{
          window.vmt_module._setModelImageBypass(0);
          window.vmt_module._setMaxIterations(7);
          window.SERVICES.vmtcore.resetDetection();
          window.SERVICES.vmtcore.setSplitterCanvasId('canvas_splitter');
          window.SERVICES.vmtcore.init('canvas_base','canvas_final');
          window.SERVICES.vmtcore.setIsLive(false);
          window.SERVICES.vmtcore.getDetection();
          self.noFaceDetectionHandle();
          
          if(window.SERVICES.vmtcore.rotations_made > 0){
            let vmtcore = window.SERVICES.vmtcore;
            let image = window.SERVICES.image;
            vmtcore.canvas_i.width = image.img.height;
            vmtcore.canvas_i.height = image.img.width;
            vmtcore.ctx_i.clearRect(0,0,vmtcore.canvas_i.width,vmtcore.canvas_i.height)
            vmtcore.ctx_i.save()
            vmtcore.ctx_i.translate(vmtcore.canvas_i.width/2, vmtcore.canvas_i.height/2)
            vmtcore.ctx_i.rotate(vmtcore.rotations_made * Math.PI/2)
            vmtcore.ctx_i.drawImage(image.img, -image.img.width/2, -image.img.height/2)
            vmtcore.ctx_i.restore(); 
            if(image.cb) image.cb();
          }
          else{
            window.SERVICES.vmtcore.render();
          }
        }
      );
      window.SERVICES.image.init('canvas_base',imageURI);

      window.SERVICES.video.pause();
      AppStore.setIsSnappedPhoto(true);
      if(this.state.isSplitterActive) this.toggleSplitter();
    }

    noFaceDetectionHandle(){
      if(!window.SERVICES.vmtcore.getIsDetected()){
        var self = this;
        window.SERVICES.notify("Face not detected, returning in 5 sec.", "ERR");
        setTimeout(()=>{
          self.returnToMainMenu();
        },5000);
      }
    }

    toggleSplitter(){
      let newSplitterState = !this.state.isSplitterActive;
      /** update state */
      this.setState({ isSplitterActive: newSplitterState});

      /** Microsoft edge bug fix */
      document.getElementById("canvas_base").style.width = document.getElementById("canvas_final").clientWidth + "px";
      document.getElementById("canvas_base").style.height = document.getElementById("canvas_final").clientHeight + "px";
      document.getElementById("canvas_splitter").style.width = document.getElementById("canvas_final").clientWidth + "px";
      document.getElementById("canvas_splitter").style.height = document.getElementById("canvas_final").clientHeight + "px";
      
      this.render();

      if(newSplitterState){
        /** splitter is now active */

        /** indicate vmtcore about splitter state */
        window.SERVICES.vmtcore.setIsSplitterActive(true);

        if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
          /** attach base canvas to splitter-image-container and make it visible */
          document.getElementById("canvas_splitter").style.display = "none";
          document.getElementById("canvas_base").style.display = "block";
        }
        else{
          /** render splitter image on splitter-image-container */
          document.getElementById("canvas_splitter").style.display = "block";
          document.getElementById("canvas_base").style.display = "none";
        }

        /** attach draggable feature */
        $( "#handlebar" ).draggable({
          axis: "x",
          containment: "parent",
          snap:true,
          snapTolerance: 30,
          drag: function() {
            $("#splitter-image-container").css("width","calc("+$( "#handlebar" ).css("left")+" + 13px)");
          },
          start: function() {
            $("#splitter-image-container").css("width","calc("+$( "#handlebar" ).css("left")+" + 13px)");
          },
          stop: function() {
            $("#splitter-image-container").css("width","calc("+$( "#handlebar" ).css("left")+" + 13px)");
          },
         });
         window.touchInit("handlebar");
      }
      else{
        /** indicate vmtcore about splitter state */
        window.SERVICES.vmtcore.setIsSplitterActive(false);
        // window.touchDeInit();
      }
      
    }

    downloadImage(){
      let vc = window.SERVICES.vmtcore;
      
      let backCanvas = document.createElement('canvas');
      backCanvas.width = vc.canvas_o.width;
      backCanvas.height = vc.canvas_o.height;
      let backCtx = backCanvas.getContext('2d');
      backCtx.drawImage(vc.canvas_o, 0,0);
      
      
      // draw base image if splitter is active
      if(this.state.isSplitterActive){
        let clientWidthToCover = document.getElementById("splitter-image-container").clientWidth;
        let widthToCover = (clientWidthToCover * vc.canvas_o.width)/ vc.canvas_o.clientWidth;
        let refCanvas = (AppStore.currentPage == AppStore.PagesEnum.LIVE)? vc.canvas_i : vc.canvas_splitter;
        backCtx.drawImage(refCanvas, 0, 0, widthToCover, vc.canvas_o.height, 0, 0, widthToCover, vc.canvas_o.height);
        backCtx.fillStyle = 'white';
        backCtx.rect((widthToCover-2), 0, 4, vc.canvas_o.height);
        backCtx.fill();
      }
      
      backCtx.fillStyle = '#af9a7f';
      backCtx.font = "14px robo-bold";
      backCtx.fillText("ALGOFACE", 5 , vc.canvas_o.height - 5);
      
      let link = document.createElement('a');
      let date = new Date();
      link.download = "Algoface_"+date.toISOString().replace(".","_")+".png";
      link.href = backCanvas.toDataURL("image/png");
      link.click();
      window.SERVICES.notify(`Downloading image.`);
    }



    returnToMainMenu(){
      if(this.state.isSplitterActive) this.toggleSplitter();
      if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
        /** pause video and reset AppStore */
        window.SERVICES.video.pause();
        AppStore.resetAppState();
      }
      else if(AppStore.isSnappedPhoto){
        /** reset AppStore, set current page to live and start */
        AppStore.resetAppState();
        AppStore.setCurrentPage(AppStore.PagesEnum.LIVE);
        this.liveViewInit();
      }
      else{
        /** reset AppStore */
        AppStore.resetAppState();
      }
    }

    liveViewInit(){
      /**
       * remove all makeup
       * reset zoom to '0'
       * render updates if necessary
       */
      window.vmt_module._resetAllMakeup();
      try{
        window.SERVICES.vmtcore.zoomTo(0);
        this.setState({zoomValue: 0});
      }
      catch(error){
        console.warn(`[ERR] unknown error at liveViewInit ${error}`);
      }

        if(AppStore.currentPage == AppStore.PagesEnum.LIVE){
          this.livePageLoad();
        }
        else if(AppStore.currentPage == AppStore.PagesEnum.MODEL){
          this.modelPageLoad();
          /** end loading page */
          AppStore.setLoaderStatus(false);
        }
        else if(AppStore.currentPage == AppStore.PagesEnum.PHOTO){
          this.uploadedImagePageLoad();
          /** end loading page */
          AppStore.setLoaderStatus(false);
        }
        else{
          /** end loading page */
          AppStore.setLoaderStatus(false);
        }
    }

    componentDidMount(){
      
      /** start loading page */
      AppStore.setLoaderStatus(true);
      
      /** wait for vmt module to load completely */
      var self = this;
      this.interval = setInterval(()=>{
        if(window.vmt_module && window.vmt_module._isVmtReady && window.vmt_module._isVmtReady()){
          self.liveViewInit();
          clearInterval(self.interval);
        }
      },500);
      
      /** enable/disable multi camera button */
      try {
        if(AppStore.currentPage === AppStore.PagesEnum.LIVE){
          window.SERVICES.video.getActiveDevices().then((devices)=>{
            if (devices.length > 1){
              self.setState({hasMultiCamera:true})
            }
            else{
              self.setState({hasMultiCamera:false})
            }
          })
        }
      } catch (error) {
        console.warn(`[ERR] unknown error invoking multi camera routine ${error}`);
      }
    }

    render(){
        return(
          <CustomContainer>
            <LoaderView/>
            <CustomCard id="viewport_card">
              <EmptySplitterContainer style={{display:`${(this.state.isSplitterActive)?'block':'none'}`}}>
                <SplitterHandleBarContainer id="handlebar">
                  <SplitterHandleBarContainerSlider></SplitterHandleBarContainerSlider>
                  <SplitterHandleBarContainerHandleIcon className="splitter-img-icon"></SplitterHandleBarContainerHandleIcon>
                </SplitterHandleBarContainer>
                <SplitterImageContainer className={`${(AppStore.currentPage === AppStore.PagesEnum.LIVE)?'canvas-container-fit-content':'canvas-container-100-percent'}`} id="splitter-image-container">
                  <CustomCanvas id="canvas_splitter"></CustomCanvas>
                  <CustomCanvas id="canvas_base"></CustomCanvas>
                </SplitterImageContainer>

              </EmptySplitterContainer>
              <canvas id="canvas_final"></canvas>
              {
                !(AppStore.currentApp === AppStore.AppsEnum.ARTIST) &&
                <MakeupAlphaControl/>
              }
              <Card.Content>
                <CustomButtonGroup>
                  <SimpleButton title="remove all makeup and transformations" icon onClick={this.resetViewParams}><Icon name='undo' /></SimpleButton>
                  <SimpleButton title="zoom in" icon onClick={this.zoomIn}><Icon name='zoom-in' /></SimpleButton>
                  <SimpleButton title="zoom out" icon onClick={this.zoomOut}><Icon name='zoom-out' /></SimpleButton>
                  { (AppStore.currentPage === AppStore.PagesEnum.LIVE) &&
                    <SimpleButton title="take a picture" icon onClick={this.takeSnap}><Icon name='camera' /></SimpleButton>
                  }
                  <SimpleButton title="toggle on/off image splitter" toggle icon active={this.state.isSplitterActive} onClick={this.toggleSplitter}><Icon name='adjust' /></SimpleButton>
                  <SimpleButton title="download image" icon onClick={this.downloadImage}><Icon name='download' /></SimpleButton>
                  {
                    (AppStore.currentPage === AppStore.PagesEnum.LIVE) && this.state.hasMultiCamera &&
                    <SimpleButton2 title="toggle camera" icon onClick={()=>window.SERVICES.video.toggleCamera()}><img className="camera-toggle-icon" src="https://img.icons8.com/ios-filled/50/000000/switch-camera.png"/></SimpleButton2>
                  }
                  <CloseButton color='google plus' title="return to main menu" icon onClick={this.returnToMainMenu}><Icon name='close' /></CloseButton>
                </CustomButtonGroup>
              </Card.Content>
            </CustomCard>
          </CustomContainer>
        );
    }
}

LiveView = observer(LiveView);
export default LiveView;
