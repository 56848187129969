import React from 'react'
import ReactDOM from 'react-dom'

class CTABanner extends React.Component {


    render() {
        return <div className="mianContainerCTA">
            <div className="mainTitle">Ready to experience the power of Virtual Makeup?</div>
            <div className="contactUsTitle">Contact us today for a free makeup AR rendering of your product.</div>
            <div className="getStartedContainer">
                <a className="anchorGetStarted" href="https://www.algoface.ai/virtual-makeup-tryon-request/?source=vtryon" target="_blank">Get Started</a>
            </div>
        </div>
    }
}

export default CTABanner;