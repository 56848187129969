import {decorate, observable, configure, action, computed} from "mobx";

class NudestStore {

  AppStore = observable({
    page              : 0,
    skin_tone         : 0,
    tanning           : -1,
    tan_most          : [],
    tan_today         : -1,
    vein_clr          : -1,
    jewel             : -1,
    selfie            : "",
    nm_passed         : false,
    results           : "",
    showLoader        : false,
    showLoader2        : false,
    NudemeterAppShow    : false
  })

  get page(){
    return this.AppStore.page;
  }

  get skin_tone(){
    return this.AppStore.skin_tone;
  }

  get tanning(){
    return this.AppStore.tanning;
  }

  get tan_most(){
    return this.AppStore.tan_most;
  }

  get tan_today(){
    return this.AppStore.tan_today;
  }

  get vein_clr(){
    return this.AppStore.vein_clr;
  }

  get jewel(){
    return this.AppStore.jewel;
  }

  get selfie(){
    return this.AppStore.selfie;
  }

  get nm_passed(){
    return this.AppStore.nm_passed;
  }

  get results(){
    return this.AppStore.results;
  }

  setLoader(val){
    this.AppStore.showLoader = val;
  }

  setLoader2(val){
      this.AppStore.showLoader2 = val;
  }

  setPassed(val){
      this.AppStore.nm_passed = val;
  }

  setNudemeterAppVal(val){
      this.AppStore.NudemeterAppShow = val;
  }

  incPage(){
      this.AppStore.page = this.AppStore.page + 1
  }

  decPage(){
      this.AppStore.page = this.AppStore.page - 1
  }

  setPage(val) {
      this.AppStore.page = val
  }

  setSkinTone(val) {
      this.AppStore.skin_tone = val
  }

  setTanning(val) {
      this.AppStore.tanning = val
  }

  pushToTanMost(val) {
      if (val >= 4) {
          this.clearTanMost();
      }else{
          let idx = this.AppStore.tan_most.indexOf(4);
          let idx2 = this.AppStore.tan_most.indexOf(5);
          if (idx > -1) {
              this.AppStore.tan_most.splice(idx, 1);
          }
          if (idx2 > -1) {
              this.AppStore.tan_most.splice(idx2, 1);
          }
      }
      if (this.AppStore.tan_most.includes(val)) {
          let idx = this.AppStore.tan_most.indexOf(val);
          if (idx > -1) {
              this.AppStore.tan_most.splice(idx, 1);
          }
      }else{
          this.AppStore.tan_most.push(val)
      }
  }

  popFromTanMost() {
      this.AppStore.tan_most.pop()
  }

  clearTanMost() {
      this.AppStore.tan_most = []
  }

  setTanToday(val) {
      this.AppStore.tan_today = val
  }

  setVeinClr(val) {
      this.AppStore.vein_clr = val
  }

  setJewel(val) {
      this.AppStore.jewel = val
  }

  setSelfie(val) {
      this.AppStore.selfie = val
  }

  setResult(val) {
      this.AppStore.results = val
  }

  cleanSlate(){
      this.AppStore.page = 0;
      this.AppStore.skin_tone = 0;
      this.AppStore.tanning = -1;
      this.AppStore.tan_today = -1;
      this.AppStore.vein_clr = -1;
      this.AppStore.jewel  = -1;
      this.AppStore.selfie = "";
      this.AppStore.results = "";
      this.clearTanMost()
  }

}

decorate(NudestStore, {
  page: computed,
  setLoader: action,
  setLoader2: action,
  setPassed: action,
  setNudemeterAppVal: action,
  incPage: action,
  decPage: action,
  setPage: action,
  setSkinTone: action,
  setTanning: action,
  pushToTanMost: action,
  popFromTanMost: action,
  clearTanMost: action,
  setTanToday: action,
  setVeinClr: action,
  setJewel: action,
  setSelfie: action,
  setResult: action,
  cleanSlate: action
})

export default new NudestStore();
